import React, {useState, useRef, useEffect} from "react";
import Layout from "./layout.js";
import ButtonPrimary from "../component/ButtonPrimary.js";
import bannerVector from "../assets/images/earth-vector.png";
import challengesIcon1 from "../assets/images/challenges-icon1.svg";
import challengesIcon2 from "../assets/images/challenges-icon2.svg";
import challengesIcon3 from "../assets/images/challenges-icon3.svg";
import challengesIcon4 from "../assets/images/challenges-icon4.svg";
import challengesBg from "../assets/images/challenges-bg.svg";
import arrowRight from "../assets/images/left-arrow.png";
import capacityImg from "../assets/images/capacity.png";
import capabilityImg from "../assets/images/capability.png";
import consultingLifecycle from "../assets/images/consulting-lifecycle.png";
import TestimonialsSection from "../component/TestimonialsSection.js";
import SectionHeading from "../component/SectionHeading.js";
import IconCard from "../component/IconCard.js";
import ServicesListSection from "../component/ServicesListSection.js";

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// Register the plugin
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default function Home({pageName}) {

  // --- Scrollspy Section Script ---
  // Helper function to get the offset for section visibility
  const getOffset = () => {
    return document.querySelector('.main-navbar-wrapper').offsetHeight; // Desktop
  };

  // Main component
  const [activeSection, setActiveSection] = useState('one');
  const navRef = useRef(null);
  const handleLinkClick = (section) => {setActiveSection(section);};
   
   // ScrollLink component for handling smooth scrolling and active link highlighting
  const ScrollLink = ({title, to, children, isActive, onClick }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onClick(to);
  
      const targetElement = document.querySelector(to);
      if (targetElement) {
        const navbar = document.querySelector('.main-navbar-wrapper');
        const navbarHeight = navbar ? navbar.offsetHeight : 0;
        window.scrollTo({
          top: targetElement.offsetTop - navbarHeight + 1,
          // behavior: 'smooth'
        });
      }
    };
    
    return (
      <a href={to} onClick={handleClick} title={title} className={isActive ? 'active round-dot' : 'round-dot'}>{children}</a>
    );
  };
 
   useEffect(() => {
     const handleScroll = () => {
       const sections = document.querySelectorAll('.hws-sec .common-scroll-section');
       let currentSection = 'one';
   
       sections.forEach((section) => {
         const sectionTop = section.offsetTop - getOffset();
         const sectionBottom = sectionTop + section.offsetHeight;
   
         if (window.pageYOffset >= sectionTop && window.pageYOffset < sectionBottom) {
          currentSection = section.getAttribute('id');
         }
       });
   
       setActiveSection(currentSection);
     };
   
     window.addEventListener('scroll', handleScroll);
     return () => {
      window.removeEventListener('scroll', handleScroll);
     };
   }, []);
 
   useEffect(() => {
     if (navRef.current) {
       const activeLink = navRef.current.querySelector('.project-detail-link.active');
       if (activeLink) {
         const containerRect = navRef.current.getBoundingClientRect();
         const linkRect = activeLink.getBoundingClientRect();
   
         if (linkRect.left < containerRect.left || linkRect.right > containerRect.right) {
           navRef.current.scrollLeft += (linkRect.left - containerRect.left) - (containerRect.width / 2 - linkRect.width / 2);
         }
       }
     }
   }, [activeSection]);

  const sectionRefs = useRef([]);
  const mainSection = useRef(null);

  useEffect(() => {
    const panels = sectionRefs.current;
    if (panels.length > 0) {
      if(window.innerWidth < 768){
        return;
      }
      ScrollTrigger.create({
        trigger: mainSection.current,
        scrub: true,
        snap: {
          snapTo: 1 / (panels.length - 1),
          duration: 0.3,
          ease: 'sine.in',
        },
        markers: false,
        start: "top 90px",
        end: "bottom bottom",
      });
    }
    ScrollTrigger.refresh();
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);
  
  return (
    <Layout pageName={pageName}>
      <section className="main-banner-section">
        <div className="container">
          <div className="row banner-row">
            <div className="col-lg-7">
              <div className="text-content">
                <h1 className="title">Growth Partner for <br /> Boutique Firms & Independent Consultants Globally</h1>
                <p className="sub-text">Win & deliver more projects</p>
                <div className="btn-grp">
                  <ButtonPrimary title="Learn More" to={"/about-us.html"} />
                  <ButtonPrimary title="Get Started" primaryBtnClass="bg-theme-light" to={"/contact-us.html"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5 img-col">
              <img src={bannerVector} alt="Banner Vector" className="img-fluid banner-vector" />
            </div>
          </div>
        </div>
      </section>

      <section className="challenge-section">
        <img src={challengesBg} alt="Background" className="line-bg img-fluid" />
        <div className="container">
          <div className="row challenge-sec-row">
            <div className="col-lg-4">
              <div className="heading-div">
                <h4 className="common-sub-heading">Your Challenges</h4>
                <h5 className="common-heading-text">Do these challenges resonate with your consulting firm?</h5>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row inner-card-row">
                <IconCard md="6" icon={challengesIcon1} title="Resource Constraints" text="Require additional consulting resources with right skill sets, without the commitment of full-time hires?" />
                <IconCard md="6" icon={challengesIcon2} title="Service Offering Gaps" text="Desire to enhance your service offerings to win larger, higher-margin projects and pitch for opportunities previously out of reach?" />
                <IconCard md="6" icon={challengesIcon3} title="Price Barrier" text="Want to offer competitive pricing without being limited by the cost of your resources?" />
                <IconCard md="6" icon={challengesIcon4} title="Client Need Gap" text="Require to fulfill client's emerging requirements to strengthen your relationship and enhance your value proposition?" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hws-sec" ref={mainSection}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 left-col">
              <div className="left-content">
                  <div className="heading-div">
                    <h4 className="common-sub-heading">How We Serve</h4>
                    <h5 className="common-heading-text">We help consulting firms scale up...</h5>
                  </div>
                  <div className="dots">
                    <ScrollLink isActive={activeSection === 'one'} onClick={() => handleLinkClick('one')} to="#one"></ScrollLink>
                    <ScrollLink isActive={activeSection === 'two'} onClick={() => handleLinkClick('two')} to="#two"></ScrollLink>
                    <ScrollLink isActive={activeSection === 'three'} onClick={() => handleLinkClick('three')} to="#three"></ScrollLink>
                  </div>
              </div>
            </div>

            <div className="col-md-8 right-col">
              <div className="inner-content common-scroll-section" id="one" ref={(el) => (sectionRefs.current[0] = el)}>
                <img src={arrowRight} alt="Arrow Right" className="img-fluid right-icon" />
                <h6 className="title">By enhancing their...</h6>
                <div className="content">
                  <div className="cards">
                    <img src={capacityImg} alt="Capacity" className="img-fluid card-imgs" />
                    <h6 className="title">Capacity</h6>
                  </div>
                  <div className="cards and-div">
                    <h6 className="title">&</h6>
                  </div>
                  <div className="cards">
                    <img src={capabilityImg} alt="Capability" className="img-fluid card-imgs" />
                    <h6 className="title">Capability</h6>
                  </div>
                </div>
              </div>

              <div className="inner-content common-scroll-section" id="two" ref={(el) => (sectionRefs.current[1] = el)}>
                <img src={arrowRight} alt="Arrow Right" className="img-fluid right-icon" />
                <h6 className="title">Across the consulting lifecycle...</h6>
                <img src={consultingLifecycle} alt="Consulting Lifecycle" className="img-fluid consulting-lifecycle-img" />
              </div>

              <div className="inner-content common-scroll-section" id="three" ref={(el) => (sectionRefs.current[2] = el)}>
                <img src={arrowRight} alt="Arrow Right" className="img-fluid right-icon" />
                <h6 className="title">By Offering...</h6>
                <div className="content">
                  <div className="single-card">
                    <div className="number-div">
                      <h6 className="number">1.</h6>
                    </div>
                    <div className="text-conent">
                      <h6 className="name">Resource Augmentation</h6>
                      <p className="p-text">Consulting talent on a weekly/monthly retainer.</p>
                    </div>
                  </div>
                  <div className="single-card">
                    <div className="number-div">
                      <h6 className="number">2.</h6>
                    </div>
                    <div className="text-conent">
                      <h6 className="name">Outcome Based Projects</h6>
                      <p className="p-text">Part of a project, or complete project ownership.</p>
                    </div>
                  </div>
                  <div className="single-card">
                    <div className="number-div">
                      <h6 className="number">3.</h6>
                    </div>
                    <div className="text-conent">
                      <h6 className="name">On-demand Support</h6>
                      <p className="p-text">Consulting support on Time & Materials basis.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ServicesListSection secText="Consulting Practice Areas" />

      <section className="why-ibc-home-sec sec-padding">
        <div className="container">
          <SectionHeading secName="why ibc" secText="Our clients work with us because we are:" />

          <div className="row inner-row">
            <div className="col-lg-6">
              <div className="inner-card">
                <h5 className="title">Purpose-driven</h5>
                <div className="message">
                  <p className="p-text">"Commitment to excellence", <span className="bold">Ricardo Miquelino</span></p>
                  <p className="p-text">"Service-minded and friendly", <span className="bold">Tony Wronkowicz</span></p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="inner-card">
                <h5 className="title">Values-driven</h5>
                <div className="message">
                  <p className="p-text">"Operate with integrity", <span className="bold">Kristen Harper</span></p>
                  <p className="p-text">"Professional and helpful", <span className="bold">Maryna Bakuntseva</span></p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="inner-card">
                <h5 className="title">Experience-driven</h5>
                <div className="message">
                  <p className="p-text">"Experienced and knowledgeable", <span className="bold">Joe Ringer</span></p>
                  <p className="p-text">"Deliver quality insights", <span className="bold">Arnaud Blandin</span></p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="inner-card">
                <h5 className="title">Diversity-driven</h5>
                <div className="message">
                  <p className="p-text">"Flexible and responsive", <span className="bold">Francesco Bax</span></p>
                  <p className="p-text">"Rapid access to talent", <span className="bold">Kaihan Krippendorff</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </section>

      <TestimonialsSection secText="Client Success Stories"/>
    </Layout>
  );
}