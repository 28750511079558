import React from "react";
import topRightLine from "../../src/assets/images/right-top-home-line.png";
import service1 from "../../src/assets/images/service1.svg";
import service2 from "../../src/assets/images/service2.svg";
import service3 from "../../src/assets/images/service3.svg";
import service4 from "../../src/assets/images/service4.svg";
import SectionHeading from "./SectionHeading";
import { Link } from "react-router-dom";

const ServicesListSection = (props = {}) => {

    return (
        <>
            <section className={`service-sec sec-padding ${props.sectionClass ? props.sectionClass : ''}`}>
                <img src={topRightLine} alt="Line" className="img-fluid top-right-line" />
                <div className="container">
                    <SectionHeading secName="our services" secText={props.secText ? props.secText : "Comprehensive Consulting Services Across:"} />
                    <div className="row inner-row">
                        <div className="col-lg-5 col-md-6">
                            <Link to={"/strategy-and-decision-making.html"} className="service-card">
                                <div className="icon-div">
                                    <img src={service1} alt="Strategy & Decision Making" className="img-fluid icon" />
                                </div>
                                <div className="text-content">
                                <h5 className="title">Strategy & Decision Making</h5>
                                <p className="read-more-btn" title="Read More">Read More <i className="fa-solid fa-arrow-right icon"></i></p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <Link to={"/operational-excellence.html"} className="service-card">
                                <div className="icon-div">
                                <img src={service2} alt="Operational Excellence" className="img-fluid icon" />
                                </div>
                                <div className="text-content">
                                <h5 className="title">Operational Excellence</h5>
                                <p className="read-more-btn" title="Read More">Read More <i className="fa-solid fa-arrow-right icon"></i></p>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-2 d-lg-flex d-none"></div>
                        <div className="col-lg-2 d-lg-flex d-none"></div>

                        <div className="col-lg-5 col-md-6">
                            <Link to={"/org-transformation-and-change-management.html"} className="service-card">
                                <div className="icon-div">
                                <img src={service3} alt="Org. Transformation & Change Management" className="img-fluid icon" />
                                </div>
                                <div className="text-content">
                                <h5 className="title">Org. Transformation & <br /> Change Management</h5>
                                <p className="read-more-btn" title="Read More">Read More <i className="fa-solid fa-arrow-right icon"></i></p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <Link to={"/digital-transformation.html"} className="service-card">
                                <div className="icon-div">
                                <img src={service4} alt="Digital Transformation" className="img-fluid icon" />
                                </div>
                                <div className="text-content">
                                <h5 className="title">Digital Transformation</h5>
                                <p className="read-more-btn" title="Read More">Read More <i className="fa-solid fa-arrow-right icon"></i></p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ServicesListSection;