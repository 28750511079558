import React, { useEffect, useState } from "react";
import Layout from "./layout.js";
import Breadcrumbs from "../component/Breadcrumbs.js";
import SectionHeading from "../component/SectionHeading.js";
import growth from "../../src/assets/images/growth.svg";
import fun from "../../src/assets/images/fun.svg";
import excellence from "../../src/assets/images/excellence.svg";
import integrity from "../../src/assets/images/integrity.svg";
import caring from "../../src/assets/images/caring.svg";
import challengesBg from "../assets/images/challenges-bg.svg";
import backgroundIcon1 from "../assets/images/backgroundIcon1.svg";
import backgroundIcon2 from "../assets/images/backgroundIcon2.svg";
import backgroundIcon3 from "../assets/images/backgroundIcon3.svg";
import backgroundIcon4 from "../assets/images/backgroundIcon4.svg";
import backgroundIcon5 from "../assets/images/backgroundIcon5.svg";
import TestimonialsSection from "../component/TestimonialsSection.js";
import { Link } from "react-router-dom";
import IconCard from "../component/IconCard.js";
import { apiCall, manageNetworkError } from "../assets/js/common.js";
import * as Constants from '../config/constants.js';

export default function About({pageName}) {

  const [teamMembers, setTeamMembers] = useState([]);

  const getteamMembersDetails = async() => {
    const response = await apiCall('get', Constants.API_BASE_URL + 'team-member-details', null, false);
    if (response?.status !== false) {
        if (response?.data?.data) {
          setTeamMembers(response?.data?.data?.teamMembers);
        }
    } else {
        manageNetworkError(response);
    }
  }

  useEffect(() => {
    getteamMembersDetails();
  }, []);

  return (
    <Layout pageName={pageName}>
      <Breadcrumbs title={pageName} text="Enabling success for consulting firms globally" />

      <section className="why-we-sec sec-padding">
        <div className="container">
          <SectionHeading secName="ORIGIN & PURPOSE" headingClassName="full-text" secText="Why We're Committed to Your Success" />

          <div className="inner-content">
            <div className="text-content">
              <div className="p-text-div">
                <p className="p-text">Ritu and I began our professional journeys at large consulting firms in their global offices.</p>
                <p className="p-text">While we gained a valuable experience, we realized our passion was in helping smaller companies.</p>
                <p className="p-text">We strongly believe that smaller and emerging businesses are the real catalysts of economic transformation. This belief led us to co-found IBC with the mission to positively impact millions of small and mid-sized businesses.</p>
                <p className="p-text">By empowering those who provide consulting services, we realized we could influence countless businesses indirectly.</p>
                <p className="p-text">We believe that every consulting firm, irrespective of its size, deserves exceptional support to grow their ventures and achieve their goals.</p>
                <p className="p-text">IBC was thus born with the purpose to…</p>
              </div>
              <h5 className="quote-text">“Help millions of consulting firms globally become scalable, sustainable, and, profitable, resulting in a massive difference in the global economy and giving our team a fulfilling exciting and nurturing life.”</h5>
            </div>
            <div className="values-div">
              <h5 className="bold-title">Our Values</h5>
              <div className="card-row">
                <div className="value-card">
                  <div className="icon-div"><img src={growth} alt="Growth" className="img-fluid value-icon" /></div>
                  <h5 className="value-title">Growth</h5>
                </div>
                <div className="value-card">
                  <div className="icon-div"><img src={fun} alt="Fun" className="img-fluid value-icon" /></div>
                  <h5 className="value-title">Fun</h5>
                </div>
                <div className="value-card">
                  <div className="icon-div"><img src={excellence} alt="Excellence" className="img-fluid value-icon" /></div>
                  <h5 className="value-title">Excellence</h5>
                </div>
                <div className="value-card">
                  <div className="icon-div"><img src={integrity} alt="Integrity" className="img-fluid value-icon" /></div>
                  <h5 className="value-title">Integrity</h5>
                </div>
                <div className="value-card">
                  <div className="icon-div"><img src={caring} alt="Caring" className="img-fluid value-icon" /></div>
                  <h5 className="value-title">Caring</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="background-sec sec-padding">
        <img src={challengesBg} alt="Background" className="line-bg img-fluid" />
        <div className="container">
          <SectionHeading secName="BACKGROUND" secText="A Team Built for Your Success" />

          <div className="row inner-card-row">
            <IconCard md="4" icon={backgroundIcon1} title="Diverse Expertise" text="Analysts to Engagement Directors across Practice Areas and Methodologies" />
            <IconCard md="4" icon={backgroundIcon2} title="Top-Tier Backgrounds" text="Experience from McKinsey, BCG, Bain, Strategy&, Accenture, Strategic Decisions Group, Amrop, Deutsche Bank, and ZS Associates" />
            <IconCard md="4" icon={backgroundIcon3} title="Scalable Team" text={<><div className="p-text-div"><p className="p-text">30 full-time internal consultants</p><p className="p-text">10 external partner firms and over 100 seasoned consultants in the ecosystem</p></div></>} />
            <IconCard md="4" icon={backgroundIcon4} title="Industry Exhaustive" text="Healthcare, Energy, Manufacturing, Technology, Finance, Retail, Real Estate, Telecommunications, Transportation & Logistics, Hospitality, and more" />
            <IconCard md="4" icon={backgroundIcon5} title="Global Reach" text={<>100+ years of combined consulting experience in the <span>US, Europe, Middle East, Southeast Asia, and Australia</span></>} />
          </div>
        </div>
      </section>

      {teamMembers?.length > 0 && (<section className="team-member-sec sec-padding">
        <div className="container">
          <SectionHeading secName="Experts" secText="Team Members" />

          <div className="row inner-row">
              {teamMembers.map((member, index) => (
                <div key={index} className="col-lg-4 col-md-6 card-col">
                  <div className="member-card">
                    {(member?.image || member?.name || member?.designation) && <div className="top-content">
                      {member?.image && <img src={Constants.BACKEND_FILE_URL + member.image} alt={member.name} className="img-fluid member-img" />}
                      <div className="name-info">
                        <h6 className="name">{member?.name}</h6>
                        <h6 className="designation">{member?.designation}</h6>
                      </div>
                    </div>}
                    <div className="bottom-content">
                      <div className="info-content">
                        {member?.expertise?.length > 0 && <div className="experties-div">
                          <h6 className="title">Areas Of expertise:</h6>
                          <ul className="list">
                            {member?.expertise?.map((item, i) => (
                              <li key={i}>{item}</li>
                            ))}
                          </ul>
                        </div>}
                        {member?.experience && <div className="experience-div">
                          <h6 className="title">Previous Experience:</h6>
                          <p className="p-text" dangerouslySetInnerHTML={{__html: member?.experience}}></p>
                        </div>}
                      </div>
                      {(member?.linkedin || member?.email_id) && <div className="social-link-div">
                        {member?.linkedin && <Link to={member?.linkedin} target="_blank" rel="noopener noreferrer" className="social-link" title="Linkedin"><i className="fa-brands fa-linkedin-in icon"></i></Link>}
                        {member?.email_id && <Link to={`https://mail.google.com/mail/?view=cm&fs=1&to=${member?.email_id}`} target="_blank" rel="noopener noreferrer" className="social-link" title="Email"><i className="fa-solid fa-envelope icon"></i></Link>}
                      </div>}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>)}

      <TestimonialsSection />
    </Layout>
  );
}