import React from "react";

const IconCard = (props = {}) => {
    return (
        <>
            <div className={`${props.md ? `col-md-${props.md}` : ''} ${props.sm ? `col-sm-${props.sm}` : ''} ${props.col ? `col-${props.col}` : ''} ${props.lg ? `col-lg-${props.lg}` : ''} card-col`}>
                <div className={`challenges-card ${props.className ? props.className : ''}`}>
                    <div className="icon-div">
                        <img src={props.icon} alt={props.title} className="icon" />
                    </div>
                    <div className="text-content">
                        <h6 className="title">{props.title}</h6>
                        <div className="p-text">{props.text}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default IconCard;