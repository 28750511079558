import React from "react";
import { Link } from "react-router-dom";

const ButtonPrimary = (props = {}) => {
    return (
        <>
            {props.type !== 'button' ?
                <Link 
                    to={props.to ? props.to : void(0)} 
                    className={`primary-arrow-btn ${props.primaryBtnClass ? props.primaryBtnClass : ''}`} 
                    onClick={props?.onClick}
                    title={props.title}
                >
                    {props.title} 
                    {props.showIcon === false ? '' : <i className={`fi ${props.icon ? props.icon : 'fi-rr-arrow-small-right'} btn-icon`}></i>}
                </Link>
                :
                <button
                    type={props.buttonType ? props.buttonType : "button"}
                    onClick={props.onClick} 
                    className={`primary-arrow-btn ${props.primaryBtnClass ? props.primaryBtnClass : ''}`} 
                    title={props.title}
                >
                    {props.title} 
                    {props.showIcon === false ? '' : <i className={`fi ${props.icon ? props.icon : 'fi-rr-arrow-small-right'} btn-icon`}></i>}
                </button>
            }
        </>
    )
}
export default ButtonPrimary;