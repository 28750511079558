import {React} from 'react';
import { Routes } from "react-router-dom";
import FrontendRoute from './routes/frontend-route.js';

import './assets/js/common.js';
import './assets/css/app.css';

function App() {
  return (
    <div className="App">
      <Routes>
        {FrontendRoute}
      </Routes> 
    </div>
  );
}

export default App;
