import React from "react";
import axios from "axios";
import * as Constants from "../../config/constants";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

// Designer 
export function handleActiveClass() {
    const currentPath = window.location.pathname;
  
    if (currentPath) {
	  const navLinks = document.querySelectorAll('li .nav-link');

	  navLinks.forEach((navLink) => {
		const href = navLink.getAttribute('href');
		if(href === currentPath){
			navLink.closest('.nav-items-class, .nav-item')?.classList.add('active');
		}
	  });
    };
}

export function showLoadingHandler(data) {
	setTimeout(()=>{
		if (data === true) {
			const parent = document.querySelector('.App');
			const newChild = `<div class="loader"><div class="loader--ring"><div></div><div></div><div></div><div></div></div></div>`;
			parent.insertAdjacentHTML('beforeend', newChild);
		}else if(data === false){
			const loader = document.querySelector(".loader");
			if (loader) {
				loader.parentNode.removeChild(loader);
			}
		}
	}, 10)
}

export function showLoader(){
	showLoadingHandler(true);
}
export function hideLoader(){
	showLoadingHandler(false);
}

export const toggleNav = () => {
  document.body.classList.toggle("nav-slide-open");
};
document.addEventListener("click", function(e) {
	if (window.innerWidth < 992 && !e.target.closest("#slide-toggle")) {
		document.body.classList.remove("nav-slide-open");
	}
});

window.addEventListener("scroll", function() {
	if (window.scrollY > 72) {
		document.querySelector(".twt-navbar")?.classList.add("fixed");
	} else {
		document.querySelector(".twt-navbar")?.classList.remove("fixed");
	}
});

// End Designer

export function valueCheck(value) {
    return ((value && value !== null && value !== "") ? value : '');
}

export function validationError(error) {
    return(error && ((<span className="text-danger">{error?.message}</span>)));
}

export const apiCall = async (method = 'get', url, data = null, isLoader = true, additionalProps = {}) => {
    let user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
    let client = axios.create({
		  baseURL: Constants.API_BASE_URL,
		  headers: {
	            'Access-Control-Allow-Origin': '*',
	            'Authorization': ((user && user.token) ? ("Bearer " + user.token) : undefined),
	            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
	      },
	});
  
    var result = {};
	var response = {};
	if(isLoader !== false) {
		showLoader();
	}
	if( method === "get" ){
    	
    	try {
    		result =  client.get(url).then((responseData) => {
				if(isLoader !== false) {
					hideLoader();
				}
				if( responseData?.data?.status_code === 500 ) {
					handleException();
				}
				if( responseData?.data?.status_code === 404 ) {
					if (additionalProps?.isPageRequest) {
						if (additionalProps?.navigate) additionalProps?.navigate('/');
					} else {
						handlePageNotFound();
					}
				}
    			response.status = true;
    			response.data = responseData.data;
	            return response;
	        }).catch((error) => {
	        	if(isLoader !== false) {
					hideLoader();
				}
	        	response.status = false;
	        	response.data = error;
	            return response;
	        });
    	} catch (error) {
    		if(isLoader !== false) {
				hideLoader();
			}
    		response.status = false;
        	response.data = error;
        	return response;
    	}
    	
    	return result;
    } else if( method === "post" ){
    	try {
    		result =  client.post(url,data).then((responseData) => {
    			if(isLoader !== false) {
					hideLoader();
				}
				if( responseData?.data?.status_code === 500 ) {
					handleException();
				}
				if( responseData?.data?.status_code === 404 ) {
					handlePageNotFound();
				}
    			response.status = true;
    			response.data = responseData.data;
	            return response;
	        }).catch((error) => {
	        	if(isLoader !== false) {
					hideLoader();
				}
	        	response.status = false;
	        	response.data = error;
	            return response;
	        });
    	}catch (error) {
    		if(isLoader !== false) {
				hideLoader();
			}
    		response.status = false;
        	response.data = error;
        	return response;
    	}
    	return result;
    }
}

alertify.defaults.transition = "slide";
alertify.defaults.theme.ok = "btn btn-primary";
alertify.defaults.theme.cancel = "btn btn-danger";
alertify.defaults.theme.input = "form-control";
export function alertifyMessage(type, message) {
	switch (type) {
		case "error":
			alertify.notify(message, "error", 5);
			break;
		case "success":
			alertify.notify(message, "success", 5);
			break;
		case "warning":
			alertify.notify(message, "warning", 5);
			break;
		case "info":
			alertify.notify(message);
			break;
		default:
			alertify.notify(message);
	}
}

export function manageNetworkError(response , t = null ) {
	
	var error_code = response.data.code ;
	if( error_code === "ERR_NETWORK"){
		alertifyMessage("error" , "Network Error. Please Contact System Administrator" );
	}
}

export function clientDateTime(dateTime) {
	return ((dateTime !== null && dateTime !== "") ? (new Date(dateTime)).toLocaleString('en-US') : '');
}

export function clientDate(date) {
	return ((date !== null && date !== "") ? (new Date(date)).toLocaleDateString() : '');
}

export function emailRegex(value, message = null) {
	if(value && value.trim()) {
		return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value.trim()) || message || "Please Enter Valid Email";
	}
}

export function mobileRegex() {
	return ({
		value: /^[6789]\d{9}$/,
		 message:   "Please Enter Valid Mobile No" //  t('invalid-enter-field-validation' , {module:t('mobile')})
	})
}

export function baseName(path){
	return path.replace(/^.*(\\|\/|:)/, '');
}

export function onlyNumberWithSpaceAndPlusSign(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^ +\d]/g, "");
}

export function ucWord( message ) {
	var str = '';
	str = message.toLowerCase().replace(/\b[a-z]/g, function(letter) {
	    return letter.toUpperCase();
	});
	return str;
}

export function onlyDecimal(e) {
	var val = e.target.value;

	if (parseInt(val) === 0) {
		var newValue = val.replace(/^0+/, "");
		e.target.value = newValue;
		return;
	}

	if (isNaN(val)) {
		val = val.replace(/[^0-9.]/g, "");
		//if (val.split(".").length > 2) val = val.replace(/\.+$/, "");
	}
	e.target.value = val;
}

export function onlyNumber(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^\d]/g, "");
}

export function naturalNumber(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^\d]/g, "").replace(/^0+/g, "");
}

export function enumText(str){
	str = str.replace(/_/g, ' ');
	str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
	    return letter.toUpperCase();
	});
	return str;
}

export function onlyNumberWithSpace(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^ \d]/g, "");
}

export function imagePreview(file) {
	var objectUrl = null;

	if(file !== null && file !== "" && file !== undefined) {
		objectUrl = URL.createObjectURL(file)
	}
	return objectUrl;
}

export const handleException = () => {
	
}

export const handlePageNotFound = () => {
	alertifyMessage("error" , 'Page Not Found');
}

//dynamic meta tags 
export const dynamicMetaTags = [
    'meta[name="title"]',
    'meta[name="description"]',
    'meta[name="keywords"]',
    'meta[name="author"]',
    'meta[name="copyright"]',
    'meta[name="publisher"]',

    'meta[property="og:title"]',
    'meta[property="og:description"]',
    'meta[property="og:url"]',
    'meta[property="og:site_name"]',
    'meta[property="og:image"]',

    'meta[property="twitter:url"]',
    'meta[property="twitter:title"]',
    'meta[property="twitter:description"]',
    'meta[property="twitter:image"]',

    'link[rel="icon"]',
    'link[rel="apple-touch-icon"]'
];