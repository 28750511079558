import React, {useState, useEffect} from "react";
import Layout from "./layout.js"; 
import Breadcrumbs from "../component/Breadcrumbs.js";
import ButtonPrimary from "../component/ButtonPrimary.js";
import indiaVector from "../assets/images/india.svg";
import usaVector from "../assets/images/usa.svg";
import ausVector from "../assets/images/australia.png";
import linkedinIcon from "../assets/images/linkedin.png";
import xIcon from "../assets/images/twitter.png";
import fbIcon from "../assets/images/facebook.png";
import youtubeIcon from "../assets/images/youtube.png";
import instaIcon from "../assets/images/instagram.png";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {decode} from 'html-entities';
import { alertifyMessage, apiCall, emailRegex, manageNetworkError } from "../assets/js/common.js";
import * as Constants from '../config/constants.js';

export default function ContactUs({pageName}) {

  const { t } = useTranslation(["translation", "errorMessage"]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const onSubmit = async (data) => {
    var submitContact = await apiCall('post', Constants.API_BASE_URL + 'submitContact', data);

    if (submitContact.status !== false) {
      if (submitContact.data.status_code === 1) {
        alertifyMessage("success", submitContact.data.message);
        reset();
      } else {
        alertifyMessage("error", submitContact.data.message);
      }
    } else {
      manageNetworkError(submitContact);
    }
  };

  const [settingInfo , setSettingInfo] = useState([]);

  const getSettingInfo = async () => {
		const getSettingData = await apiCall( 'get' , Constants.SETTING_URL , null , false );
		if( getSettingData.status !== false ){
      if(getSettingData?.data?.data){
        setSettingInfo(getSettingData.data.data?.recordInfo);
      }
		} else {
			manageNetworkError(getSettingData);
		}
	}

  useEffect( () => {
	  getSettingInfo(); 
  } , []);  

  return (
    <Layout pageName={pageName}>
      <Breadcrumbs title={pageName} text="Let's Connect and Collaborate" />

      <section className="contact-section border-bottom"> 
        <div className="container">
          <div className="inner-content">
            <div className="left-cnt">
              <div className="address-mdiv">
                <div className="address">
                  <div className="icon-div">
                    <img src={indiaVector} alt="India Vector" className="img-fluid icon" />
                  </div>
                  <div className="address-cnt">
                    <h5 className="country-title">INDIA</h5>
                    <ul className="address-list">
                      {settingInfo?.address &&  (
                        <li className="text"><address dangerouslySetInnerHTML={{__html: decode(settingInfo.address)}}></address></li>
                      )}
                      <li className="text">102, Soho, Kharadi, Pune - 412207</li>
                    </ul>
                  </div>
                </div>

                <div className="address">
                  <div className="icon-div">
                    <img src={usaVector} alt="USA Vector" className="img-fluid icon" />
                  </div>
                  <div className="address-cnt">
                    <h5 className="country-title">USA</h5>
                    <p className="text">10703 127th AVE NE, Kirkland, WA 98033-4728</p>
                  </div>
                </div>

                <div className="address">
                  <div className="icon-div">
                    <img src={ausVector} alt="Australia Vector" className="img-fluid icon" />
                  </div>
                  <div className="address-cnt">
                    <h5 className="country-title">Australia</h5>
                    <p className="text">Melbourne</p>
                  </div>
                </div>
              </div>

              {settingInfo?.email && (
                <div className="mail-div">
                  <h5 className="title">Mail Us On:</h5>
                    <Link to={"mailto:" + settingInfo.email} className="p-text mail-text">{settingInfo.email}</Link>
                </div>
              )}

              {(settingInfo?.facebook_link || settingInfo?.instagram_link || settingInfo?.twitter_link || settingInfo?.linkedin_link || settingInfo?.youtube_link) && (
                <div className="social-div">
                  <h5 className="title">Go Social:</h5>
                  <div className="contact-social">
                    {settingInfo?.facebook_link && (
                      <li className="d-inline-block">
                        <Link to={settingInfo.facebook_link} target="_blank" rel="noopener noreferrer" className="social-list-item" title="Facebook"><img src={fbIcon} alt="Facebook" className="icn-img" /></Link>
                      </li>
                    )}
                    {settingInfo?.instagram_link &&(
                      <li className="d-inline-block">
                        <Link to={settingInfo.instagram_link} target="_blank" rel="noopener noreferrer" className="social-list-item" title="Instagram"><img src={instaIcon} alt="Instagram" className="icn-img" /></Link>
                      </li>
                    )}
                    {settingInfo?.twitter_link &&(
                      <Link to={settingInfo.twitter_link} target="_blank" rel="noopener noreferrer" className="social-list-item" title="X"><img src={xIcon} alt="X" className="icn-img" /></Link>
                    )}
                    {settingInfo?.linkedin_link &&(
                      <Link  to={settingInfo.linkedin_link} target="_blank" rel="noopener noreferrer" className="social-list-item" title="Linkedin"><img src={linkedinIcon} alt="Linkedin" className="icn-img" /></Link>
                    )}
                    {settingInfo?.youtube_link &&(
                    <li className="d-inline-block">
                      <Link  to={settingInfo.youtube_link} target="_blank" rel="noopener noreferrer" className="social-list-item" title="Youtube"><img src={youtubeIcon} alt="Youtube" className="icn-img" /></Link>
                    </li>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="right-cnt">
              <div className="contact-card">
                <div className="heading-div">
                  <h5 className="heading">Write To Us:</h5>
                  <p className="p-text">For Consulting Services, Partnerships, or Career Opportunities:</p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} id="contact-us-form" autoComplete="off">
                  <div className="form-div">
                    <div className="form-group">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="name" name="name" placeholder={`Name ${<><span className="text-danger">*</span></>}`} autoComplete="off"
                          {...register("name", {
                            required: true,
                            setValueAs: (value) => value.trim(),
                          })}
                        />
                        <label htmlFor="name">Name<span className="text-danger">*</span></label>
                      </div>
                      {errors.name && (<span className="text-danger">{t('errorMessage:required-enter-name')}</span>)}
                    </div>

                    <div className="form-group">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="email" name="email" placeholder={`Email ${<><span className="text-danger">*</span></>}`} autoComplete="off"
                          {...register("email", {
                            required: t('errorMessage:required-enter-email'),
                            validate: ((value => emailRegex(value, t('errorMessage:invalid-enter-field-validation' , {module:t('email')}))))
                          })}
                        />
                        <label htmlFor="email">Email<span className="text-danger">*</span></label>
                      </div>
                      {errors.email && (<p className="mb-0 text-danger">{errors.email?.message}</p>)}
                    </div>

                    <div className="form-group">
                      <div className="form-floating">
                        <textarea className="form-control" id="detailed_requirements" name="detailed_requirements" rows={5} placeholder={`How can we help? ${<><span className="text-danger">*</span></>}`} autoComplete="off"
                          {...register("detailed_requirements", {
                            required: true,
                            setValueAs: (value) => value,
                          })}
                        />
                        <label htmlFor="detailed_requirements">How Can We Help?<span className="text-danger">*</span></label>
                      </div>
                      {errors.detailed_requirements && (<span className="text-danger">{t('errorMessage:required-enter-detailed-requirements')}</span>)}
                    </div>

                    <div className="form-group d-none">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="webname" name="webname" placeholder="webname" autoComplete="off" 
                          {...register("webname", {
                            required: false
                          })}
                        />
                        <label htmlFor="webname">Webname<span className="text-danger">*</span></label>
                      </div>
                    </div>
                    
                    <div className="form-group">
                      <div className="submitbtn-div">
                        <ButtonPrimary type="button" buttonType="submit" title="Submit" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}