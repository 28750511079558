import React from "react";
import Layout from "./layout.js";
import Breadcrumbs from "../component/Breadcrumbs.js";
import SectionHeading from "../component/SectionHeading.js";
import IconCard from "../component/IconCard.js";
import entryIcon from "../assets/images/entry.svg";
import growthStrategyIcon from "../assets/images/growth-strategy.svg";
import innovationIcon from "../assets/images/innovation.svg";
import turnAroundIcon from "../assets/images/turn-around.svg";
import investmentIcon from "../assets/images/investment.svg";
import maStrategyIcon from "../assets/images/ma-strategy.svg";
import esgStrategyIcon from "../assets/images/esg-strategy.svg";
import strategicAgendaIcon from "../assets/images/strategic-agenda.svg";
import marketSizingIcon from "../assets/images/market-sizing.svg";
import marketAssessmentIcon from "../assets/images/market-assessment.svg";
import voiceOfCustomerIcon from "../assets/images/voice-of-customer.svg";
import competitiveAnalysisIcon from "../assets/images/competitive-analysis.svg";
import benchmarkingIcon from "../assets/images/benchmarking.svg";
import macroEnviromentIcon from "../assets/images/macro-enviroment.svg";
import valueRiskIcon from "../assets/images/value-risk.svg";
import strategicDueIcon from "../assets/images/strategic-due.svg";
import synergyAnalysisIcon from "../assets/images/synergy-analysis.svg";
import strategyService1 from "../assets/images/strategy-service1.png";
import strategyService2 from "../assets/images/strategy-service2.png";
import strategyService3 from "../assets/images/strategy-service3.png";
import strategyService4 from "../assets/images/strategy-service4.png";
import strategyService5 from "../assets/images/strategy-service5.png";
import strategyService6 from "../assets/images/strategy-service6.png";
import strategyService7 from "../assets/images/strategy-service7.png";
import strategyService8 from "../assets/images/strategy-service8.png";
import strategyService9 from "../assets/images/strategy-service9.png";
import strategyService10 from "../assets/images/strategy-service10.png";
import strategyService11 from "../assets/images/strategy-service11.png";
import strategyService12 from "../assets/images/strategy-service12.png";
import Fancybox from "../assets/js/fancybox.js";

export default function StrategyDecisionMaking({pageName}) {

  return (
    <Layout pageName={pageName}>
      <Breadcrumbs title="Services" text={pageName} />

      <section className="consult-outcome-sec sec-padding">
        <div className="container">
          <SectionHeading headingClassName="full-text" secName="Consulting Outcomes" secText="We deliver end-to-end consulting outcomes…" />

          <div className="card-row row justify-content-center">
            <IconCard lg="3" md="4" col="6" className="border-card" icon={entryIcon} title="GTM / Market-entry Strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={growthStrategyIcon} title="Growth Strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={innovationIcon} title="Innovation Strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={turnAroundIcon} title="Turnaround Strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={investmentIcon} title="Investment Strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={maStrategyIcon} title="M&A Strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={esgStrategyIcon} title="ESG Strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={strategicAgendaIcon} title="Strategic Agenda"/>
          </div>
        </div>
      </section>

      <section className="project-input-sec sec-padding">
        <div className="container">
          <SectionHeading headingClassName="full-text" secName="Project Inputs" secText="…and also support you with the foundational project inputs that form the backbone of these outcomes:" />

          <div className="card-row row justify-content-center">
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={marketSizingIcon} title="Market Segmentation & Sizing"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={marketAssessmentIcon} title="Market Assessment"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={voiceOfCustomerIcon} title="Voice of Customer Analysis"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={competitiveAnalysisIcon} title="Competitive Analysis"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={benchmarkingIcon} title="Benchmarking"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={macroEnviromentIcon} title="Macro Environment Analysis"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={valueRiskIcon} title="Value & Risk analysis"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={strategicDueIcon} title="Strategic Due Diligence"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={synergyAnalysisIcon} title="Synergy Analysis"/>
          </div>
        </div>
      </section>

      {/* 01 */}
      <section className="case-study sec-padding">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Growth Strategy & Strategic Planning for a Global 3PL Company</>} />
            <div className="row case-study-row">
              <div className="col-lg-6">
                <div className="case-study-card">
                  <h5 className="title">Project Stakeholders</h5>
                  <ul className="content">
                    <li className="p-text">The end client is a premier provider of end-to-end global supply chain solutions and eCommerce services, delivering comprehensive 3PL services worldwide</li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="case-study-card">
                  <h5 className="title">Project Scope</h5>
                  <ul className="content">
                    <li className="p-text">Market sizing and trend analysis, competitor profiling, strategic options evaluation, and quantifying top-line strategies to guide decision-making and promote growth and sustainability</li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="case-study-card">
                  <h5 className="title">Approach and Execution</h5>
                  <ul className="content">
                    <li className="p-text">Performed market sizing - evaluated TAM, SAM for the client's target market (incl. regional and industry-wise cuts)</li>
                    <li className="p-text">Conducted in-depth competitor analysis to identify key industry players, their market strategies, and competitive positioning</li>
                    <li className="p-text">Quantified top-line impact of strategic actions to enhance business growth, providing measurable goals and metrics for strategic decision-making</li>
                    <li className="p-text">Assessed and recommended M&A targets for business growth, strengthening market position, and enhancing operational effectiveness</li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-12">
                  <div className="case-study-card">
                    <h5 className="title">Key Project Deliverables</h5>
                    <div className="content key-project-content">
                    <Fancybox options={{Carousel: {infinite: false,},}}>
                      <div className="row case-study-row">
                        <div className="col-lg-6">
                          <div className="inner-card">
                            <div className="img-div"><img src={strategyService1} alt="strategy-service1" className="img" /></div>
                            <li className="p-text">Attractive segments based on scale, margin, capabilities, growth, market size and segments of the industry</li>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="inner-card">
                            <div className="img-div"><img src={strategyService2} alt="strategy-service2" className="img" /></div>
                            <li className="p-text">Competitive landscape with details of key relevant competitors for value-added services</li>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="inner-card">
                            <div className="img-div"><img src={strategyService3} alt="strategy-service3" className="img" /></div>
                            <li className="p-text">Strategic initiatives include prioritizing market opportunities based on scale, client needs, right to win, an feasibility.</li>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="inner-card">
                            <div className="img-div"><img src={strategyService4} alt="strategy-service4" className="img" /></div>
                            <li className="p-text">Attractive adjacencies for expansion, and "tuck-in" acquisition targets</li>
                          </div>
                        </div>
                      </div>
                    </Fancybox>
                    </div>
                  </div>
              </div>

            </div>
        </div>
      </section>

      {/* 02 */}
      <section className="case-study sec-padding">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Business Diagnostic Exercise for Manufacturing Company</>} />

          <div className="row case-study-row">
            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Stakeholders</h5>
                <ul className="content">
                  <li className="p-text">The client is a mid-sized pipe manufacturer</li>
                  <li className="p-text">Regional management consulting firm specializing in business transformation and operational excellence</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Scope</h5>
                <ul className="content">
                  <li className="p-text">In-depth business diagnostic exercise for the pipe manufacturing firm; gap assessment and corrective action, business metrics for performance evaluation</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Approach and Execution</h5>
                <ul className="content">
                  <li className="p-text">Studied as-is department wise organization structure, reporting & governance structure, and current policies and procedures</li>
                  <li className="p-text">Conducted interviews with senior stakeholders for each department to gain visibility of department’s key activities and responsibilities</li>
                  <li className="p-text">Benchmarked as-is state with to-be state to identify gaps for improvement; recommended improvement initiatives for respective gaps</li>
                  <li className="p-text">Studied master list of functional KPIs and prioritized business performance metrics for each department with department stakeholders</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Key Project Deliverables</h5>
                <div className="content key-project-content">
                <Fancybox options={{Carousel: {infinite: false,},}}>
                  <div className="row case-study-row">
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={strategyService5} alt="strategy-service5" className="img" /></div>
                        <li className="p-text">Actionable insights for improvement of the organization's performance</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={strategyService6} alt="strategy-service6" className="img" /></div>
                        <li className="p-text">Comprehensive commission structure for sales and marketing functions</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={strategyService7} alt="strategy-service7" className="img" /></div>
                        <li className="p-text">Key policies for the business including credit policy, rebate policy, 3rd party commission policy, etc.</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={strategyService8} alt="strategy-service8" className="img" /></div>
                        <li className="p-text">Benchmark of  organization's performance vis-à-vis the market performance</li>
                      </div>
                    </div>
                  </div>
                </Fancybox>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* 03 */}
      <section className="case-study sec-padding border-bottom">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Business Feasibility Study in e-Commerce Sector</>} />

          <div className="row case-study-row">
            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Stakeholders</h5>
                <ul className="content">
                  <li className="p-text">{`The end client is a global leader in e-commerce; Chinese company with revenue ${'>'} US$ 150 Billion`}</li>
                  <li className="p-text">Regional management consulting firm with a focus on market studies & strategy development</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Scope</h5>
                <ul className="content">
                  <li className="p-text">Business feasibility for e-commerce on behalf of the world B2C e-commerce, Asia's largest online retail marketplace, and local Saudi Arabia business entity followed by high-level financial modelling and go/no-go decision</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Approach and Execution</h5>
                <ul className="content">
                  <li className="p-text">Studied global and regional demand side factors for e-commerce: Internet penetration, mobile phone ownership, customer awareness etc.</li>
                  <li className="p-text">Studied supply-side factors determining the success of online retail in Saudi Arabia; included an overview of regulations and customer concerns</li>
                  <li className="p-text">Undertook psychographic analysis of online shoppers to gauge factors contributing towards the growth of e-commerce in Saudi Arabia</li>
                  <li className="p-text">Developed a high-level financial model to perform scenario testing on business valuation under different sets of business conditions</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Key Project Deliverables</h5>
                <div className="content key-project-content">
                <Fancybox options={{Carousel: {infinite: false,},}}>
                  <div className="row case-study-row">
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={strategyService9} alt="strategy-service9" className="img" /></div>
                        <li className="p-text">Proprietary framework for business feasibility assessment of e-commerce business in Saudi Arabia and MENA region</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={strategyService10} alt="strategy-service10" className="img" /></div>
                        <li className="p-text">Assessment and actionable insights</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={strategyService11} alt="strategy-service11" className="img" /></div>
                        <li className="p-text">Supply side readiness in e-commerce in Saudi Arabia for holistic review of sector</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={strategyService12} alt="strategy-service12" className="img" /></div>
                        <li className="p-text">Driving factors for online shopping</li>
                      </div>
                    </div>
                  </div>
                </Fancybox>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </Layout>
  );
}