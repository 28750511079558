import * as twtConfig from "./twt_config";  

export const LOGIN_COOKIE_NAME = twtConfig.LOGIN_COOKIE_NAME;
export const API_BASE_URL = twtConfig.API_BASE_URL;
export const BACKEND_FILE_URL = twtConfig.BACKEND_FILE_URL;
export const SITE_URL = twtConfig.SITE_URL;
export const BASE_PATH = twtConfig.BASE_PATH;
export const LANGUAGE_FOLDER_URL = twtConfig.LANGUAGE_FOLDER_URL;
 
export const SETTING_URL = '/getSettingInfo';
export const UPDATE_TOTAL_VISITOR_URL= '/updateTotalVisitor';

export const SELECTION_YES = 'Yes';
export const SELECTION_NO = 'No';
export const PAGE_NOT_FOUND_URL = '/page-not-found';
export const LINKEDIN_SHARE_LINK = 'https://www.linkedin.com/sharing/share-offsite?url=';
export const X_SHARE_LINK = 'https://x.com/intent/post?url=';
export const INSIGHTS_URL = '/insights.html';
export const INSIGHTS_KEY_NAME = 'insights';
