import React, { useEffect , useState } from "react";
import Header from './includes/header';
import Footer from './includes/footer';
import { seo } from '../seo';
import * as Constants from "../config/constants";  
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import {  apiCall , dynamicMetaTags, manageNetworkError, valueCheck } from "../assets/js/common";
import icon from "../assets/images/icon.png";
import logo from "../assets/images/logo.png";

export default function Layout({ children , pageName }) {
  const { t } = useTranslation(["translation", "errorMessage"]);
  const [settingInfo, setSettingInfo] = useState([]);
  const user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));

  const getSettingInfo = async () => {
		const getSettingData = await apiCall( 'get' , Constants.SETTING_URL , null , false );
		if( getSettingData.status !== false ){
      if(getSettingData?.data?.data){
        setSettingInfo(getSettingData.data.data?.recordInfo);
      }
		} else {
			manageNetworkError(getSettingData);
		}
	}

  const updateTotalVisitor = async () => {
    
    const response = await apiCall( 'get' , Constants.UPDATE_TOTAL_VISITOR_URL , null , false);
    if(response?.status !== false) {
      if(response?.data?.status_code === 1) {
        const userData = ((user && user?.token) ? user : {});
        if(response?.data?.data) {
          userData.hasVisited = response.data.data?.hasVisited;
          localStorage.setItem( Constants.LOGIN_COOKIE_NAME , JSON.stringify(userData));
        }
      }
    } else {
        manageNetworkError(response);
    }
  }

  useEffect(() => {
    getSettingInfo();
  } , []);
  
  useEffect(() => {
    seo({
      title: pageName + ' | ' + (settingInfo?.site_title ? settingInfo.site_title : ''),
    });
  }, [t,settingInfo,pageName]);

  useEffect(() => {
    if( !user || !user.hasVisited || user.hasVisited !== Constants.SELECTION_YES ) {
      updateTotalVisitor();
    }
    // eslint-disable-next-line
  } , [user]);

  useEffect(() => {
    const removeTagList = dynamicMetaTags.join(',');
    document.querySelectorAll(removeTagList).forEach(e => {
      if(!e.getAttribute('data-react-helmet')) {
        e.remove();
      }
    });
  } , []);
  
  return (
    <HelmetProvider>
      <Helmet>
        <meta name='title' content={(pageName !== null && pageName !== "" ? pageName : 'Home' ) + ' | ' + valueCheck(settingInfo?.site_title)} />
        <meta name='description' content={ valueCheck(settingInfo?.site_description) } />
        <meta name='keywords' content={ valueCheck(settingInfo?.site_keywords) } />
        <meta name='author' content={ valueCheck(settingInfo?.meta_author)  } />
        <meta name='copyright' content={ valueCheck(settingInfo?.site_title) } />
        <meta name='publisher' content={ valueCheck(settingInfo?.meta_author)  } />
        
        <meta property='og:title' content={ valueCheck(settingInfo?.site_title) } />
        <meta property='og:description' content={valueCheck(settingInfo?.site_description) } />
        <meta property="og:url" content={Constants.SITE_URL} />
        <meta property='og:site_name' content={ valueCheck(settingInfo?.site_title) } />
        <meta property='og:image' content={settingInfo?.website_og_icon ? (Constants.BACKEND_FILE_URL + settingInfo.website_og_icon) : icon} />

        <meta property="twitter:url" content={Constants.SITE_URL} />
        <meta property='twitter:title' content={valueCheck(settingInfo?.site_title)} />
        <meta property='twitter:description' content={ valueCheck(settingInfo?.site_description) } />
        <meta property='twitter:image' content={settingInfo?.website_logo ? (Constants.BACKEND_FILE_URL + settingInfo.website_logo) : logo} />
        
        <link rel="icon" href={settingInfo?.website_fav_icon ? (Constants.BACKEND_FILE_URL + settingInfo.website_fav_icon) : icon} />
        <link rel="apple-touch-icon" href={settingInfo?.website_fav_icon ? (Constants.BACKEND_FILE_URL + settingInfo.website_fav_icon) : icon} />
      </Helmet>

      <Header settingInfo={settingInfo}/>
      <main>{children}</main> 
      <Footer settingInfo={settingInfo}/>
    </HelmetProvider>
  )
}