import React from "react";
import breadcrumbLine from "../../src/assets/images/breadcrumb-line.svg";

const Breadcrumbs = (props = {}) => {
    return (
        <>
            <section className={`breadcrumbs-sec ${props.className ? props.className : ''}`}>
                <img src={breadcrumbLine} alt="Breadcrumb" className="img-fluid breadcrumb-line" />
                <div className="container">
                    <h1 className="page-name">{props.title}</h1>
                    <h6 className="text">{props.text}</h6>
                </div>
            </section>
        </>
    )
}
export default Breadcrumbs;