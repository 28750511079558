import React, {useEffect, useState} from "react";
import { Link, NavLink } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@flaticon/flaticon-uicons/css/all/all.css";
import * as Constants from "../../config/constants";
import {toggleNav, handleActiveClass} from '../../assets/js/common';
import logo from "../../assets/images/header-logo.png";

export default function Header({ settingInfo }) {

  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownClick = (event) => {
    setIsOpen(!isOpen);
    event.stopPropagation();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {

    handleActiveClass();

    if (window.innerWidth > 991) {
      const dropdowns = document.querySelectorAll('.twt-navbar .nav-item.dropdown, .twt-navbar .dropdown-submenu');
    
      dropdowns.forEach(dropdown => {
        const dropdownMenu = dropdown.querySelector('.dropdown-menu');
    
        dropdown.addEventListener('mouseover', () => {
          dropdownMenu.classList.remove('show');
          dropdownMenu.classList.add('show');
        });

        dropdown.addEventListener('mouseleave', () => {
          dropdownMenu.classList.remove('show');
        });
      });
    }

    // setTimeout(() => {
    //   const navMain = document.querySelector("#navMain");
    //   const minHeight = navMain.offsetHeight;
    //   document.querySelector(".main-navbar-wrapper").style.minHeight = minHeight + "px";
    // }, 200);
  }, []);

  return (
  <header>
  <div className="main-navbar-wrapper">
    <nav className="navbar twt-navbar twt-navbar-common navbar-expand-lg" id="navMain">
      <div className="container">
        <Link className="navbar-brand" to={"/"}>
          {settingInfo?.website_logo ? (
            <img src={Constants.BACKEND_FILE_URL + settingInfo.website_logo} alt={settingInfo.site_title} className="brand-logo-img"/>
          ) : (
            <img src={logo} alt={settingInfo.site_title} className="brand-logo-img img-fluid"/>
          )}
        </Link>
        <button type="button" className="btn navbar-toggler border-0" id="slide-toggle" onClick={toggleNav}><span className="navbar-dash" /><span className="navbar-dash" /><span className="navbar-dash" /></button>
        <div className="slide navbar-slide" id="slideNav">
          <ul className="navbar-nav ms-auto p-0" id="elastic_parent" data-bs-targets=".nav-item">
            <li className="nav-item"><Link className="nav-link" title="Home" to={"/"}>Home</Link></li>
            <li className="nav-item"><Link className="nav-link" title="About Us" to={"/about-us.html"}>About Us</Link></li>
            <li className="nav-item dropdown">
              <Link to={"/services.html"} className="nav-link dropdown-toggle d-none d-lg-flex" title="Services"><span>Services</span></Link>
              <a href="home" className="nav-link dropdown-toggle d-lg-none d-flex" onClick={handleDropdownClick} title="Services" data-bs-toggle="dropdown"><span>Services</span></a>
              <ul className="dropdown-menu">
                <li><NavLink to={"/services.html"} className={({ isActive }) => (isActive ? 'dropdown-item d-lg-none d-flex active' : 'dropdown-item d-lg-none d-flex')} title="All Services">All Services</NavLink></li>
                <li><NavLink to={"/strategy-and-decision-making.html"} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Strategy & Decision Making">Strategy & Decision Making</NavLink></li>
                <li><NavLink to={"/operational-excellence.html"} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Operational Excellence">Operational Excellence</NavLink></li>
                <li><NavLink to={"/org-transformation-and-change-management.html"} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Org. Transformation & Change Management">Org. Transformation & Change Management</NavLink></li>
                <li><NavLink to={"/digital-transformation.html"} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Digital Transformation">Digital Transformation</NavLink></li>
              </ul>
            </li>
            <li className="nav-item"><Link className="nav-link" title="Insights" to={"/insights.html"}>Insights</Link></li>
            <li className="nav-item"><Link className="nav-link" title="Careers" to={"/careers.html"}>Careers</Link></li>
            <li className="nav-item">
              <Link className="nav-link" to={"/contact-us.html"} title="Contact Us">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  {/* navbar end  */}
  </header>
  );
}