import React from "react";
import Layout from "./layout.js";
import Breadcrumbs from "../component/Breadcrumbs.js";
import * as Constants from "../config/constants.js";
import { Link } from "react-router-dom";

export default function PrivacyPolicy({pageName}) {

  return (
    <Layout pageName={pageName}>
      <Breadcrumbs title={pageName} text="How We Use Your Information" />

      <section className="sec-padding border-bottom"> 
        <div className="container">
          <div className="terms-policy-content">
            <div className="p-text-div">
              <p className="p-text">Thank you for choosing to be part of our community at IBC Consultants ("Company", "we", "us", or "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy, or our practices with regards to your personal information, please contact us at <Link to={"mailto:info@consult-ibc.com"}>info@consult-ibc.com</Link>.</p>
              <p className="p-text">When you visit our website (<Link to={Constants.SITE_URL}>{Constants.SITE_URL}</Link>), and more generally, use any of our services (the "Services", which include the Website), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Services immediately.</p>
            </div>
            <div className="p-text-div">
              <h5 className="heading">What Information Do We Collect?</h5>
              <h6 className="sub-heading">Personal Information You Disclose to Us</h6>
              <p className="p-text">We collect personal information that you provide to us.</p>
              <p className="p-text">We collect personal information that you voluntarily provide to us when registering at the Services expressing an interest in obtaining information about us or our products and services, when participating in activities on the Services, or otherwise contacting us.</p>
              <p className="p-text">The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
              <ul style={{margin: "0"}}>
                <li>Name</li>
                <li>Contact information (email, phone number)</li>
                <li>Employment information (company, job title)</li>
                <li>Payment information (credit card numbers, billing address)</li>
              </ul>
            </div>

            <div className="p-text-div">
              <h6 className="sub-heading">Information Automatically Collected</h6>
              <p className="p-text">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
            </div>

            <div className="p-text-div">
              <h6 className="sub-heading">How Do We Use Your Information?</h6>
              <p className="p-text">We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</p>
              <p className="p-text">We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.</p>
              <ul style={{margin: "0"}}>
                <li>To facilitate account creation and logon process</li>
                <li>To send administrative information to you</li>
                <li>To fulfill and manage your orders</li>
                <li>To post testimonials (with your consent)</li>
                <li>To protect our Services (e.g., fraud monitoring and prevention)</li>
                <li>To enforce our terms, conditions, and policies</li>
                <li>For other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience</li>
              </ul>
            </div>

            <div className="p-text-div">
              <h6 className="sub-heading">Will Your Information Be Shared with Anyone?</h6>
              <p className="p-text">We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>
              <ul style={{margin: "0"}}>
                <li>Compliance with Laws. We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process.</li>
                <li>Vital Interests and Legal Rights. We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
              </ul>
            </div>

            <div className="p-text-div">
              <h6 className="sub-heading">Do We Use Cookies and Other Tracking Technologies?</h6>
              <p className="p-text">We may use cookies and other tracking technologies to collect and store your information.</p>
              <p className="p-text">We use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.</p>
            </div>

            <div className="p-text-div">
              <h6 className="sub-heading">How Do We Handle Your Social Logins?</h6>
              <p className="p-text">If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</p>
              <p className="p-text">Our Services offer you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platforms.</p>
            </div>

            <div className="p-text-div">
              <h6 className="sub-heading">How Long Do We Keep Your Information?</h6>
              <p className="p-text">We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</p>
              <p className="p-text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
            </div>

            <div className="p-text-div">
              <h6 className="sub-heading">How Do We Keep Your Information Safe?</h6>
              <p className="p-text">We aim to protect your personal information through a system of organizational and technical security measures.</p>
              <p className="p-text">We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information.</p>
            </div>

            <div className="p-text-div">
              <h6 className="sub-heading">What Are Your Privacy Rights?</h6>
              <p className="p-text">You have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</p>
              <p className="p-text">Depending on where you are located geographically, the applicable privacy laws may allow you to access and request to correct, amend, delete, or limit the use of your personal information. Under these laws, you may have the right to request access to, update, or delete the information we have on you. You can typically control these actions yourself through your account settings and tools that we offer, or you can contact us directly for assistance.</p>
            </div>

            <div className="p-text-div">
              <h6 className="sub-heading">Controls for Do-Not-Track Features</h6>
              <p className="p-text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.</p>
            </div>

            <div className="p-text-div">
              <h6 className="sub-heading">Do We Make Updates to This Policy?</h6>
              <p className="p-text">In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
              <p className="p-text">We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</p>
            </div>

            <div className="p-text-div">
              <h6 className="sub-heading">How Can You Contact Us About This Policy?</h6>
              <p className="p-text">If you have questions or comments about this policy, you may email us at <Link to={"mailto:info@consult-ibc.com"}>info@consult-ibc.com</Link>.</p>
              <p className="p-text">We hope this Privacy Policy helps you understand, and feel more confident about, our use of your personal data. If you have any further questions or suggestions, don't hesitate to reach out to us.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}