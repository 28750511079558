import React, { useEffect, useState } from "react";
import Layout from "../layout.js";
import Breadcrumbs from "../../component/Breadcrumbs.js";
import ButtonPrimary from "../../component/ButtonPrimary.js";
import InsightsCard from "../../component/InsightsCard.js";
import { apiCall, manageNetworkError } from "../../assets/js/common.js";
import * as Constants from "../../config/constants.js";

export default function Insights({ pageName }) {

  const [filterData, setFilterData] = useState({});
  const [renderRequired, setRenderRequired] = useState(true);
  const [recordDetails, setRecordDetails] = useState([]);
  const [contentTypeDetails, setContentTypeDetails] = useState([]);
  const [contentTopicDetails, setContentTopicDetails] = useState([]);
  const [firstRequest, setFirstRequest] = useState(true);
  const state = JSON.parse(localStorage.getItem(Constants.INSIGHTS_KEY_NAME));

  const getRecordDetails = async (tagId = "") => {
    if (firstRequest) {
      filterData.filter_request = Constants.SELECTION_NO;
      if(tagId) {
        filterData.content_topics = tagId;
      }
    } else {
      filterData.filter_request = Constants.SELECTION_YES;
    }
    
    const getRecordDetail = await apiCall('post', Constants.API_BASE_URL + 'content', filterData, false);
    if (getRecordDetail?.status !== false) {
      if (getRecordDetail?.data?.data) {
        setRecordDetails(getRecordDetail?.data?.data?.contentDetails);
        if (getRecordDetail?.data?.data?.contentTypeDetails) {
          setContentTypeDetails(getRecordDetail?.data?.data?.contentTypeDetails);
        }
        if (getRecordDetail?.data?.data?.contentTopicDetails) {
          setContentTopicDetails(getRecordDetail?.data?.data?.contentTopicDetails);
        }
      }
    } else {
      manageNetworkError(getRecordDetail);
    }
  }

  useEffect(() => {
		if(renderRequired) {
      if(state) {
        getRecordDetails(state?.id);
      } else {
        getRecordDetails();
      }
			setRenderRequired(false);
      if (firstRequest) setFirstRequest(false);
		}
    // eslint-disable-next-line
  }, [renderRequired]);

  const handleFilterField = (e, renderRequired = false) => {
    setFilterData(filterData => {
      return { ...filterData, [e.target.name]: e.target.value }
    });
    if (renderRequired) {
      setRenderRequired(true);
    }
  }

  const handleKeyPress = (e) => {
    if ((e.keyCode === 13 || e.which === 13)) {
      setRenderRequired(true);
    }
  }

  const handleSelectTag = (tag) => {
    const selectedTag = contentTopicDetails.find(topic => topic?.value === tag);
    if (selectedTag && selectedTag?.id) {
      document.querySelector('[name="content_topics"]').value = selectedTag?.id;
      setFilterData({
        ...filterData,
        content_topics: selectedTag?.id
      })
      window.scrollTo(0, 0);
    }
  }

  useEffect(() => {
    if(state?.tag && contentTopicDetails?.length) {
      handleSelectTag(state?.tag);
      localStorage.removeItem(Constants.INSIGHTS_KEY_NAME);
    }
    // eslint-disable-next-line
  } , [state, contentTopicDetails]);

  return (
    <Layout pageName={pageName}>
      <Breadcrumbs title={pageName} text="Explore Strategic Insights and Key Resources" />

      <section className="sec-padding border-bottom">
        <div className="container">
          <div className="filter-row-parent">
            <div className="row filter-row w-lg-100">
              <div className="col-md-4">
                <input type="text" name="search" placeholder="Search" className="form-control bordered-input" onKeyPress={handleKeyPress} onChange={e => handleFilterField(e)}/>
              </div>
              <div className="col-md-4">
                <select name="content_type" className="form-select bordered-input" onChange={e => handleFilterField(e, true)}>
                  <option value="">All Content Type</option>
                  {contentTypeDetails.length > 0 && contentTypeDetails.map((contentType, key) => (
                    <option key={key} value={contentType?.id}>{contentType?.value}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <select name="content_topics" className="form-select bordered-input" onChange={e => handleFilterField(e, true)}>
                  <option value="">All Content Topics</option>
                  {contentTopicDetails.length > 0 && contentTopicDetails.map((contentTopic, key) => (
                    <option key={key} value={contentTopic?.id}>{contentTopic?.value}</option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <ButtonPrimary icon="fi fi-rr-search" title="Search" onClick={e => setRenderRequired(true)}/>
            </div>
          </div>
          {recordDetails.length > 0 ? 
            <div className="insights-grid">
              {recordDetails.map((recordInfo, key) => (
                <InsightsCard
                  key={key}
                  title={recordInfo?.content_title}
                  text={recordInfo?.short_description}
                  date={recordInfo?.publish_date}
                  category={recordInfo?.content_type}
                  tags={recordInfo?.content_tags}
                  to={Constants.SITE_URL + recordInfo?.slug + ".html"}
                  link={Constants.SITE_URL + recordInfo?.slug + ".html"}
                  onTagClick={(e, tag) => {handleSelectTag(tag); setRenderRequired(true);}}
                />
              ))}
            </div>
            : 
            <div className="text-center">No Record Found.</div>}
        </div>
      </section>
    </Layout>
  );
}