import React, { useEffect, useState } from "react";
import Layout from "../layout.js";
import Breadcrumbs from "../../component/Breadcrumbs.js";
import linkedinIcon from "../../assets/images/linkedin.png";
import xIcon from "../../assets/images/twitter.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import InsightsCard from "../../component/InsightsCard.js";
import * as Constants from "../../config/constants.js";
import { apiCall, manageNetworkError } from "../../assets/js/common.js";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

export default function InsighttDeails({ pageName }) {

  const { slug } = useParams();
  const navigate = useNavigate();
  const [recordInfo, setRecordInfo] = useState({});
  const [recommendedPosts, setRecommendedPosts] = useState({});
  const [insighttDeailsPageName, setInsighttDeailsPageName] = useState(pageName);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const getRecordDetails = async() => {
    const getRecordDetail = await apiCall('get', Constants.API_BASE_URL + 'content-page/' + slug.replace('.html', ''), null, true, { isPageRequest: true, navigate: navigate });
    if (getRecordDetail?.status !== false) {
      if (getRecordDetail?.data?.data) {
        setRecordInfo(getRecordDetail?.data?.data?.contentDetails);
        setRecommendedPosts(getRecordDetail?.data?.data?.recommendedPosts);
        setInsighttDeailsPageName(getRecordDetail?.data?.data?.contentDetails?.content_title);
      }
    } else {
      manageNetworkError(getRecordDetail);
    }
  }

  useEffect(() => {
		getRecordDetails();
    // eslint-disable-next-line
  }, [slug]);

  const handleMouseDown = (e, tag) => {
    localStorage.setItem(Constants.INSIGHTS_KEY_NAME, JSON.stringify(tag));
  }

  return (
    <Layout pageName={insighttDeailsPageName}>
      <Breadcrumbs title={recordInfo?.content_type} className="text-singleline" text={recordInfo?.content_title} />

      <section className="sec-padding border-bottom">
        <div className="container">
          <div className="row insight-details-row">
            <div className={"col-lg-8 col-left"}>
              <div className="insight-details-div">
                <h5 className="title">{recordInfo?.content_title}</h5>
                <div className="info-mdiv">
                  <div className="info">
                    {(recordInfo?.content_type || recordInfo?.publish_date || recordInfo?.authors) && <div className="left-cnt">
                      {recordInfo?.content_type && <h5 className="info-text"><i className="fi fi-rr-list icon"></i>{recordInfo?.content_type}</h5>}
                      <h5 className="info-text"><i className="fi fi-rr-calendar-day icon"></i>{recordInfo?.publish_date}</h5>
                      <h5 className="info-text"><i className="fi fi-rr-user icon"></i>{recordInfo?.authors}</h5>
                    </div>}
                    {(recordInfo?.content_title) && (
                      <div className="social-div">
                        <h5 className="title">Share:</h5>
                        <div className="contact-social">
                          <Link to={Constants.LINKEDIN_SHARE_LINK + window?.location?.href} target="_blank" rel="noopener noreferrer" className="social-list-item" title="Linkedin"><img src={linkedinIcon} alt="Linkedin" className="icn-img" /></Link>
                          <Link to={Constants.X_SHARE_LINK + window?.location?.href} target="_blank" rel="noopener noreferrer" className="social-list-item" title="X"><img src={xIcon} alt="X" className="icn-img" /></Link>
                        </div>
                      </div>
                    )}
                  </div>
                  {recordInfo?.content_tags?.length > 0 && <div className="tags-mdiv">
                    <h5 className="title">Tags:</h5>
                    <div className="tags">
                      {recordInfo?.content_tags?.map((tag, key) => (
                        <Link to={Constants.INSIGHTS_URL} state={{ tag: tag }} key={key} onMouseDown={(e) => handleMouseDown(e, tag)} onClick={(e) => handleMouseDown(e, tag)} className="tag pe-auto">{tag?.tag}</Link>
                      ))}
                    </div>
                  </div>}
                </div>
                {(recordInfo?.short_description || recordInfo?.content || recordInfo?.pdf_file_path) && <div className="ck-content">
                  <p>{recordInfo?.short_description}</p>
                  <p dangerouslySetInnerHTML={{ __html: recordInfo?.content }}></p>
                  {recordInfo?.pdf_file_path && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <div style={{height: "550px"}}>
                      <Viewer
                        fileUrl={'data:application/pdf;base64,' + recordInfo?.pdf_file_path}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </div>
                  </Worker>}
                </div>}
              </div>
              {recordInfo?.content_title &&
                <Link to={"/insights.html"} title="Back To Insights" className="primary-arrow-btn mt-4"><i className="fi fi-rr-arrow-small-left btn-icon"></i> Back To Insights</Link>
              }
            </div>
            <div className="col-lg-4 col-right">
              <div className="recommended-post-div">
                <h5 className="title">Recommended Posts</h5>
                <div className="posts">
                  {recommendedPosts?.length > 0 && recommendedPosts?.map((recommendedPost, key) => (
                    <InsightsCard
                      key={key}
                      mainClassName="recommended-card"
                      title={recommendedPost?.content_title}
                      text={recommendedPost?.short_description}
                      date={recommendedPost?.publish_date}
                      category={recommendedPost?.content_type}
                      link={Constants.SITE_URL + recommendedPost?.slug + ".html"}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}