import React from "react";
import Layout from "./layout.js";
import Breadcrumbs from "../component/Breadcrumbs.js";
import SectionHeading from "../component/SectionHeading.js";
import IconCard from "../component/IconCard.js";
import turnAroundIcon from "../assets/images/turn-around.svg";
import investmentIcon from "../assets/images/investment.svg";
import maStrategyIcon from "../assets/images/ma-strategy.svg";
import esgStrategyIcon from "../assets/images/esg-strategy.svg";
import strategicAgendaIcon from "../assets/images/strategic-agenda.svg";
import marketSizingIcon from "../assets/images/market-sizing.svg";
import voiceOfCustomerIcon from "../assets/images/voice-of-customer.svg";
import competitiveAnalysisIcon from "../assets/images/competitive-analysis.svg";
import benchmarkingIcon from "../assets/images/benchmarking.svg";
import macroEnviromentIcon from "../assets/images/macro-enviroment.svg";
import valueRiskIcon from "../assets/images/value-risk.svg";
import strategicDueIcon from "../assets/images/strategic-due.svg";
import synergyAnalysisIcon from "../assets/images/synergy-analysis.svg";
import marketAssessmentIcon from "../assets/images/market-assessment.svg";

export default function OrgTransformationChangeManagement({pageName}) {

  return (
    <Layout pageName={pageName}>
      <Breadcrumbs title="Services" text={pageName} />

      <section className="consult-outcome-sec sec-padding">
        <div className="container">
          <SectionHeading headingClassName="full-text" secName="Consulting Outcomes" secText="We deliver end-to-end consulting outcomes…" />

          <div className="card-row row justify-content-center">
            <IconCard lg="3" md="4" col="6" className="border-card" icon={synergyAnalysisIcon} title="Org. restructuring strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={marketSizingIcon} title="Post-Merger integration plan"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={voiceOfCustomerIcon} title="Talent management strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={maStrategyIcon} title="Reward Recognition Plan"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={benchmarkingIcon} title="Succession Plan"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={valueRiskIcon} title="Change Readiness"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={macroEnviromentIcon} title="Change Adoption Plan"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={esgStrategyIcon} title="Leadership Development Program"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={investmentIcon} title="Risk Management Plan"/>
          </div>
        </div>
      </section>

      <section className="project-input-sec sec-padding">
        <div className="container">
          <SectionHeading headingClassName="full-text" secName="Project Inputs" secText="…and also support you with the foundational project inputs that form the backbone of these outcomes:" />

          <div className="card-row row justify-content-center">
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={strategicAgendaIcon} title="Climate Survey"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={benchmarkingIcon} title="Customer satisfaction study"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={maStrategyIcon} title="M&A cultural fit assessment"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={esgStrategyIcon} title="Leadership effectiveness assessment"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={competitiveAnalysisIcon} title="Competence and Capacity assessment"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={strategicDueIcon} title="Change readiness assessment"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={valueRiskIcon} title="Change Impact assessment"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={turnAroundIcon} title="L&D Framework design"/>
          </div>
        </div>
      </section>

      {/* 01 */}
      <section className="case-study sec-padding">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Performance Improvement and Leadership Development for Captive Centre</>} />

          <div className="row case-study-row">
            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Stakeholders</h5>
                <ul className="content">
                  <li className="p-text">The end client is an industry-leading global firm specializing in talent management and advisory services.</li>
                  <li className="p-text">Captive Knowledge Centre - dedicated research and information services centre.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Scope</h5>
                <ul className="content">
                  <li className="p-text">Career pathways that align with learning pathways, spanning from entry-level positions to the N-1 leadership team. </li>
                  <li className="p-text">Pathways will encompass functional expertise, professional soft skills, and leadership capabilities.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Approach and Execution</h5>
                <ul className="content">
                  <li className="p-text"><b>Diagnostic Analysis:</b> Employed a multi-layered approach to assess performance, including individual, team, department, and captive centre levels, utilizing a comprehensive performance matrix.</li>
                  <li className="p-text"><b>Customer Satisfaction NPS Implementation:</b> Developed and implemented a Customer Satisfaction Net Promoter Score (NPS) system to gauge and enhance client satisfaction levels.</li>
                  <li className="p-text"><b>Leadership Stakeholder Interviews:</b> Conducted in-depth interviews with key stakeholders from all major global offices across continents, including partners, researchers, and executive assistants.</li>
                  <li className="p-text"><b>Priority Identification and Strategy Development:</b> Analyzed data to identify the top five priorities and devised related strategies with clear timelines for implementation.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Key Project Deliverables</h5>
                <div className="content key-project-content">
                  <div className="row case-study-row">
                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={marketAssessmentIcon} alt="Icon" /></div>
                        <li className="p-text">Performance metrics enhancements to align with business objectives.</li>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={strategicAgendaIcon} alt="Icon" /></div>
                        <li className="p-text">Implement key result framework to enhance leadership skills and goals. </li>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={turnAroundIcon} alt="Icon" /></div>
                        <li className="p-text">Guidance to empower career progression. Design, develop and implement career pathways to professional growth and advancement</li>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={strategicDueIcon} alt="Icon" /></div>
                        <li className="p-text">Enhance customer satisfaction by identifying areas of improvement and device strategies to enhance customer experience.</li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 02 */}
      <section className="case-study sec-padding">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Global Talent Mapping for Accountancy and Advisory Giant</>} />

          <div className="row case-study-row">
            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Stakeholders</h5>
                <ul className="content">
                  <li className="p-text">Global Talent Acquisition team of one of the global Accountancy and Advisory giant.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Scope</h5>
                <ul className="content">
                  <li className="p-text">Market study on Technology Talent and create the pipeline of Technology Consulting leaders across geographies.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Approach and Execution</h5>
                <ul className="content">
                  <li className="p-text"><b>Market Analysis:</b> Analyzed global talent availability across various geographical regions, encompassing sub-segments within digital consulting</li>
                  <li className="p-text"><b>Digital Talent Identification:</b> Mapped and profiled tech consulting principals/partners and above from major countries across continents, including those in tech consulting, management consulting, and tech consultants working within corporations</li>
                  <li className="p-text"><b>Market Research:</b> Conducted expert interviews to gain insights into the tech consulting landscape across countries and understand emerging talent demands.</li>
                  <li className="p-text"><b>Talent Analysis:</b> Analyzed mapped candidates, including their demographics, publications, and compensation.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Key Project Deliverables</h5>
                <div className="content key-project-content">
                  <div className="row case-study-row">
                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={voiceOfCustomerIcon} alt="Icon" /></div>
                        <li className="p-text">Comprehensive talent mapping plan</li>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={synergyAnalysisIcon} alt="Icon" /></div>
                        <li className="p-text">In-depth profiles mapped and created a database of 3000+ Principals/Partners/Business leaders in the technology consulting domain</li>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={esgStrategyIcon} alt="Icon" /></div>
                        <li className="p-text">Detail analysis report of global talent availability for sub-segments across geographical variations</li>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={macroEnviromentIcon} alt="Icon" /></div>
                        <li className="p-text">Report with recommendations to plan the hiring strategy and talent marketing to attract candidates</li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 03 */}
      <section className="case-study sec-padding border-bottom">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Performance Framework Revamp</>} />

          <div className="row case-study-row">
            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Stakeholders</h5>
                <ul className="content">
                  <li className="p-text">An industry-leading global Search and Organization advisory firm - senior management, Engagement Managers, Principals, Partners and Senior Partners.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Scope</h5>
                <ul className="content">
                  <li className="p-text">A comprehensive performance evaluation system addressing revenue attribution challenges in consulting partnerships, emphasizing role clarity, and consistency, and ensuring fair distribution and alignment with organizational goals.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Approach and Execution</h5>
                <ul className="content">
                  <li className="p-text"><b>Analysis:</b> Analyzed the Org Structure, Roles and responsibilities, competitor model, and market benchmarks in similar industries.</li>
                  <li className="p-text"><b>Redesign Framework:</b> Designed a dual counting system incorporating quantitative and qualitative metrics. Established clear criteria and implementation procedures.</li>
                  <li className="p-text"><b>Pilot Implementation:</b> Tested framework on past 4-year performance data, live projects, and gathered feedback for adjustments.</li>
                  <li className="p-text"><b>Strategic communication for Rollout and Training:</b> Designed a detailed change management approach and implemented it to drive changes transparently and provide ongoing support.</li>
                  <li className="p-text"><b>Monitoring and Improvement:</b> Monitored the framework performance for a few months and performed minor tweaking before firmwide rollout.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Key Project Deliverables</h5>
                <div className="content key-project-content">
                  <div className="row case-study-row">
                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={strategicDueIcon} alt="Icon" /></div>
                        <li className="p-text">Comprehensive document mapping the job roles and sales process flow.</li>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={investmentIcon} alt="Icon" /></div>
                        <li className="p-text">Assess the financial implications of various incentive models and align with organizations culture.</li>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={marketAssessmentIcon} alt="Icon" /></div>
                        <li className="p-text">Step-by-step strategy outlining the implementation process and governance.</li>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="number-card">
                        <div className="icon-div"><img src={valueRiskIcon} alt="Icon" /></div>
                        <li className="p-text">Performance scorecard matrix for demonstrating enhancements and progress.</li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}