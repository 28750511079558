import React from "react";
import Layout from "./layout.js";
import Breadcrumbs from "../component/Breadcrumbs.js";
import SectionHeading from "../component/SectionHeading.js";
import IconCard from "../component/IconCard.js";
import innovationIcon from "../assets/images/innovation.svg";
import turnAroundIcon from "../assets/images/turn-around.svg";
import investmentIcon from "../assets/images/investment.svg";
import esgStrategyIcon from "../assets/images/esg-strategy.svg";
import strategicAgendaIcon from "../assets/images/strategic-agenda.svg";
import marketSizingIcon from "../assets/images/market-sizing.svg";
import marketAssessmentIcon from "../assets/images/market-assessment.svg";
import voiceOfCustomerIcon from "../assets/images/voice-of-customer.svg";
import competitiveAnalysisIcon from "../assets/images/competitive-analysis.svg";
import benchmarkingIcon from "../assets/images/benchmarking.svg";
import macroEnviromentIcon from "../assets/images/macro-enviroment.svg";
import valueRiskIcon from "../assets/images/value-risk.svg";
import strategicDueIcon from "../assets/images/strategic-due.svg";
import synergyAnalysisIcon from "../assets/images/synergy-analysis.svg";
import operationalService1 from "../assets/images/operational-service1.png";
import operationalService2 from "../assets/images/operational-service2.png";
import operationalService3 from "../assets/images/operational-service3.png";
import operationalService4 from "../assets/images/operational-service4.png";
import operationalService5 from "../assets/images/operational-service5.png";
import operationalService6 from "../assets/images/operational-service6.png";
import operationalService7 from "../assets/images/operational-service7.png";
import operationalService8 from "../assets/images/operational-service8.png";
import operationalService9 from "../assets/images/operational-service9.png";
import operationalService10 from "../assets/images/operational-service10.png";
import Fancybox from "../assets/js/fancybox.js";

export default function OperationalExcellence({pageName}) {

  return (
    <Layout pageName={pageName}>
      <Breadcrumbs title="Services" text={pageName} />

      <section className="consult-outcome-sec sec-padding">
        <div className="container">
          <SectionHeading headingClassName="full-text" secName="Consulting Outcomes" secText="We deliver end-to-end consulting outcomes…" />

          <div className="card-row row justify-content-center">
            <IconCard lg="3" md="4" col="6" className="border-card" icon={marketAssessmentIcon} title="Delivery excellence strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={innovationIcon} title="New Product Development"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={strategicDueIcon} title="management strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={voiceOfCustomerIcon} title="Distribution strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={investmentIcon} title="Working Capital reduction strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={marketSizingIcon} title="Supply Chain excellence (inbound & outbound)"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={synergyAnalysisIcon} title="Business Process re-engineering (BPR)"/>
          </div>
        </div>
      </section>

      <section className="project-input-sec sec-padding">
        <div className="container">
          <SectionHeading headingClassName="full-text" secName="Project Inputs" secText="…and also support you with the foundational project inputs that form the backbone of these outcomes:" />

          <div className="card-row row justify-content-center">
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={strategicAgendaIcon} title="Service Quality assessment"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={valueRiskIcon} title="Inventory portfolio analysis"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={voiceOfCustomerIcon} title="Distribution effectiveness assessment"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={macroEnviromentIcon} title="Production Planning analysis"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={competitiveAnalysisIcon} title="New product development process analysis"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={esgStrategyIcon} title="Process Mapping"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={turnAroundIcon} title="Project Management"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={benchmarkingIcon} title="Program Management"/>
          </div>
        </div>
      </section>

      {/* 01 */}
      <section className="case-study sec-padding">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Detailed Operating Model Design for Museums in Saudi Arabia</>} />

          <div className="row case-study-row">
            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Stakeholders</h5>
                <ul className="content">
                  <li className="p-text">Museums Commission under Ministry of Culture, KSA</li>
                  <li className="p-text">A leading strategic advisory and investments firm, based in Riyadh</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Scope</h5>
                <ul className="content">
                  <li className="p-text">An operating model for storage and maintenance department of museums i.e., processes and procedures, organization structure, role & responsibilities and operating guidelines for storage of articles and others</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Approach and Execution</h5>
                <ul className="content">
                  <li className="p-text">Analyzed the typology of museum articles part of the collection, and categorized them basis globally accepted naming standards and guidelines</li>
                  <li className="p-text">Undertook market research to identify global standards and industry best practices for storage and maintenance of museum articles</li>
                  <li className="p-text">Conducted client workshops to design target operating model - high-level business cycles, procedures and processes for operations</li>
                  <li className="p-text">Delivered documents for operationalization of museums - Operating Manual, Training Manual, Authority Matrix, Process Workflows</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Key Project Deliverables</h5>
                <div className="content key-project-content">
                <Fancybox options={{Carousel: {infinite: false,},}}>
                  <div className="row case-study-row">
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={operationalService1} alt="operationalService1" className="img" /></div>
                        <li className="p-text">High-level business cycles and constituent processes for article handling within museums</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={operationalService2} alt="operationalService2" className="img" /></div>
                        <li className="p-text">Organization structure for specific department with outlining of designation across all levels - senior to junior</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={operationalService3} alt="operationalService3" className="img" /></div>
                        <li className="p-text">Translate procedure for article handling, storage and maintenance into process flow maps and workflows</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={operationalService4} alt="operationalService4" className="img" /></div>
                        <li className="p-text">Roles and responsibilities, and authority matrix to demarcate decision-making across department</li>
                      </div>
                    </div>
                  </div>
                </Fancybox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 02 */}
      <section className="case-study sec-padding">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Revamping the production planning process to improve delivery performance and reduce WIP</>} />

          <div className="row case-study-row">
            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Stakeholders</h5>
                <ul className="content">
                  <li className="p-text">A manufacturer of refractory bricks in India catering to the requirements of the steel, cement, glass and other process industries.</li>
                  <li className="p-text">The company owned by the largest conglomerate in India before being acquired by a Japanese group</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Scope</h5>
                <ul className="content">
                  <li className="p-text">To improve product delivery performance, reduce WIP, and unlock latent capacity, with a focus on Dolomite refractories</li>
                  <li className="p-text">Introducing new processes and systems and facilitating their adoption</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Approach and Execution</h5>
                <ul className="content">
                  <li className="p-text">Diagnosed the production process and identified the change efforts needed</li>
                  <li className="p-text">Designed and implemented a new production plan applying principles and practices of the Theory of Constraints (TOC)</li>
                  <li className="p-text">Introduced target buffers for Dolomite Finished Goods and an attendant buffer management system </li>
                  <li className="p-text">Introduced new measurement and reward systems that incentivized changed behavior</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Key Project Deliverables</h5>
                <div className="content key-project-content">
                <Fancybox options={{Carousel: {infinite: false,},}}>
                  <div className="row case-study-row justify-content-center">
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={operationalService5} alt="operationalService5" className="img" /></div>
                        <li className="p-text">Doubling of the On Time in Full (OTIF) performance, resulted in a sales increase of more than 15%. Design and implementation of a vastly improved production planning system.</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={operationalService6} alt="operationalService6" className="img" /></div>
                        <li className="p-text">Established new measurement systems for operations directly linked with the financial performance of the organization.</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={operationalService7} alt="operationalService7" className="img" /></div>
                        <li className="p-text">Profits of the organization increased by 40% as a result of these improvements over 18 months</li>
                      </div>
                    </div>
                  </div>
                </Fancybox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 03 */}
      <section className="case-study sec-padding border-bottom">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Creating a win-win in the distribution chain to achieve higher market share</>} />

          <div className="row case-study-row">
            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Stakeholders</h5>
                <ul className="content">
                  <li className="p-text">The client is a locking solutions provider in India providing locks and allied security devices for residential, commercial and office spaces.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Scope</h5>
                <ul className="content">
                  <li className="p-text">Improve on its stagnating market share in an increasingly competitive market</li>
                  <li className="p-text">Identify the cause of the lack of availability of the right products to sell at the right time to the distributors. </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Approach and Execution</h5>
                <ul className="content">
                  <li className="p-text">Mapped and analyzed the inventories held by distributors versus the actual demand and its impact on the sales of the company</li>
                  <li className="p-text">Designed and created a pull-based outbound supply chain to resolve the issues and create a win-win with the distributors</li>
                  <li className="p-text">Designed a pull system of inventory transfer from the production organization to the distributors to reduce slow-moving inventory </li>
                  <li className="p-text">Created a target buffer mechanism for Finished goods and buffer management-based production planning and execution on the shop floor.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Key Project Deliverables</h5>
                <div className="content key-project-content">
                <Fancybox options={{Carousel: {infinite: false,},}}>
                  <div className="row case-study-row justify-content-center">
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={operationalService8} alt="operationalService8" className="img" /></div>
                        <li className="p-text">In-depth analysis of client's data and provided insights to increase the ROI from around 8% to more than 20%</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={operationalService9} alt="operationalService9" className="img" /></div>
                        <li className="p-text">Review credit policy for business to increase the distributor ROI from around 8% to more than 20%, and the freeing up of cash of the distributors, leading to an increase in their sales</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={operationalService10} alt="operationalService10" className="img" /></div>
                        <li className="p-text">Market share increased from 32% to 39% during this period and the profits of the company almost doubled</li>
                      </div>
                    </div>
                  </div>
                  </Fancybox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}