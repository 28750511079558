var hostName = window.location.hostname;
var protocol = window.location.protocol;
var port = window.location.port;
var siteName = '';
// eslint-disable-next-line
var frontendFolder = '/frontend-react/';
var backendFolder = '/backend/';

export const BASE_PATH = "/";

export const LOGIN_COOKIE_NAME = "IBC_CONSULTANTS";
export const SITE_TITLE = 'IBC Consultants';
export const API_BASE_URL = protocol + "//" + hostName + siteName + backendFolder + "api/";

//Use in Laravel-starter
export const BACKEND_FILE_URL = protocol + "//" + hostName + siteName + backendFolder + 'storage/app/uploads/';

export const SITE_URL = protocol + "//" + hostName + BASE_PATH;
export const LANGUAGE_FOLDER_URL = SITE_URL;