import React from "react";
import Layout from "./layout.js";
import Breadcrumbs from "../component/Breadcrumbs.js";
import SectionHeading from "../component/SectionHeading.js";
import IconCard from "../component/IconCard.js";
import innovationIcon from "../assets/images/innovation.svg";
import turnAroundIcon from "../assets/images/turn-around.svg";
import maStrategyIcon from "../assets/images/ma-strategy.svg";
import esgStrategyIcon from "../assets/images/esg-strategy.svg";
import strategicAgendaIcon from "../assets/images/strategic-agenda.svg";
import competitiveAnalysisIcon from "../assets/images/competitive-analysis.svg";
import strategicDueIcon from "../assets/images/strategic-due.svg";
import digitalService1 from "../assets/images/digital-service1.png";
import digitalService2 from "../assets/images/digital-service2.png";
import digitalService3 from "../assets/images/digital-service3.jpg";
import digitalService5 from "../assets/images/digital-service5.png";
import digitalService6 from "../assets/images/digital-service6.png";
import digitalService7 from "../assets/images/digital-service7.png";
import digitalService8 from "../assets/images/digital-service8.png";
import digitalService9 from "../assets/images/digital-service9.png";
import digitalService10 from "../assets/images/digital-service10.png";
import Fancybox from "../assets/js/fancybox.js";

export default function DigitalTransformation({pageName}) {

  return (
    <Layout pageName={pageName}>
      <Breadcrumbs title="Services" text={pageName} />

      <section className="consult-outcome-sec sec-padding">
        <div className="container">
          <SectionHeading headingClassName="full-text" secName="Consulting Outcomes" secText="We deliver end-to-end consulting outcomes…" />

          <div className="card-row row justify-content-center">
            <IconCard lg="3" md="4" col="6" className="border-card" icon={competitiveAnalysisIcon} title="Digital Strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={turnAroundIcon} title="Digital Change Management"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={maStrategyIcon} title="Business Intelligence & Data Analytics"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={esgStrategyIcon} title="AI/ML & Gen-AI Strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={competitiveAnalysisIcon} title="Digital Product Strategy"/>
            <IconCard lg="3" md="4" col="6" className="border-card" icon={innovationIcon} title="Intelligent Automations"/>
          </div>
        </div>
      </section>

      <section className="project-input-sec sec-padding">
        <div className="container">
          <SectionHeading headingClassName="full-text" secName="Project Inputs" secText="…and also support you with the foundational project inputs that form the backbone of these outcomes:" />

          <div className="card-row row justify-content-center">
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={competitiveAnalysisIcon} title="Digital Maturity Assessment"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={strategicAgendaIcon} title="Forecasting & Predictive Modelling"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={turnAroundIcon} title="Custom App Development"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={competitiveAnalysisIcon} title="Dashboard & Reports"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={innovationIcon} title="Innovation Workshops"/>
            <IconCard lg="3" md="4" col="6" className="trans-card" icon={strategicDueIcon} title="AI Pilots & POCs"/>
          </div>
        </div>
      </section>

      {/* 01 */}
      <section className="case-study sec-padding">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Analytics Maturity Assessment and Team Design</>} />

          <div className="row case-study-row">
            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Stakeholders</h5>
                <ul className="content">
                  <li className="p-text">Startup in AI and Analytics Space</li>
                  <li className="p-text">Product based Organization</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Scope</h5>
                <ul className="content">
                  <li className="p-text">In-depth assessment of team structure, skills, maturity and sales pipeline</li>
                  <li className="p-text">Strategy roadmap for $10M revenue</li>
                  <li className="p-text">Maturity, gap and team assessment</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Approach and Execution</h5>
                <ul className="content">
                  <li className="p-text">Conducted Demand Size Analysis for lead prioritization</li>
                  <li className="p-text">Defined high-level Product Strategy and Roadmap through SME and stakeholder interviews</li>
                  <li className="p-text">Assessed existing solutions, features, and team skills through interviews and frameworks</li>
                  <li className="p-text">Designed a phased company setup for Design, Development, Pilot, and Scale-Up Distribution</li>
                  <li className="p-text">Developed a strategic implementation roadmap with planned Team Mix sequencing</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Key Project Deliverables</h5>
                <div className="content key-project-content">
                <Fancybox options={{Carousel: {infinite: false,},}}>
                  <div className="row case-study-row justify-content-center">
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={digitalService1} alt="digitalService1" className="img" /></div>
                        <li className="p-text">Demand Assessment and Product Level Go-To Market Design</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={digitalService2} alt="digitalService2" className="img" /></div>
                        <li className="p-text">Analytics Maturity Assessment the Organization</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={digitalService3} alt="digitalService3" className="img" /></div>
                        <li className="p-text">Team Structuring and Gaps Identification for New Org. Model</li>
                      </div>
                    </div>
                  </div>
                </Fancybox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 02 */}
      <section className="case-study sec-padding">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Speech Based Lead Prioritization</>} />

          <div className="row case-study-row">
            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Stakeholders</h5>
                <ul className="content">
                  <li className="p-text">Healthcare Laboratory Firm who are catering to Maternity Care</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Scope</h5>
                <ul className="content">
                  <li className="p-text">AI for lead prioritization through speech analysis</li>
                  <li className="p-text">Replace manual processes for efficiency</li>
                  <li className="p-text">Strategic handling of conversations</li>
                  <li className="p-text">Assisted the sales team in closing gaps related to recommended post-delivery maternity scans</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Approach and Execution</h5>
                <ul className="content">
                  <li className="p-text">Developed Speech Pipeline for Call Recording processing.</li>
                  <li className="p-text">Transcribed and translated recordings, extracting speech features.</li>
                  <li className="p-text">Applied Topic Modeling for conversation topic assignment.</li>
                  <li className="p-text">Utilized Unsupervised Learning for call segmentation.</li>
                  <li className="p-text">Conducted deep profiling of call personas within segments.</li>
                  <li className="p-text">Generated analytics translation for outreach strategy formulation</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Key Project Deliverables</h5>
                <div className="content key-project-content">
                <Fancybox options={{Carousel: {infinite: false,},}}>
                  <div className="row case-study-row justify-content-center">
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={digitalService5} alt="digitalService5" className="img" /></div>
                        <li className="p-text">Segments of each call and generated clusters such as Active Engagement, Warm Prospects, Low Engagement, and No Response.</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={digitalService6} alt="digitalService6" className="img" /></div>
                        <li className="p-text">Examine each cluster across various conversations and align the outreach strategy. Prioritize Active Engagements for subsequent follow-up </li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={digitalService7} alt="digitalService7" className="img" /></div>
                        <li className="p-text">Examine each cluster across various conversations and align the outreach strategy. Prioritize Active Engagements for subsequent follow-up </li>
                      </div>
                    </div>
                  </div>
                </Fancybox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 03 */}
      <section className="case-study sec-padding border-bottom">
        <div className="container">
          <SectionHeading secName="Case study" secText={<>Visualization Solution for Population Risk Assessment</>} />

          <div className="row case-study-row">
            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Stakeholders</h5>
                <ul className="content">
                  <li className="p-text">Philippines based Health Insurer who supports Employers and wants to provide Value Added services for Health </li>
                  <li className="p-text">Mid Size Healthcare TPA </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="case-study-card">
                <h5 className="title">Project Scope</h5>
                <ul className="content">
                  <li className="p-text">Visualizations to understand Population risk and care variation for assisting formulation of programs and policy </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Approach and Execution</h5>
                <ul className="content">
                  <li className="p-text">Design High-Level Risk Assessment Framework: Demographics, utilization, disease burden.</li>
                  <li className="p-text">Understand raw data, identify gaps, and set up additional data requirements.</li>
                  <li className="p-text">Create a patient-centric executive dashboard for Population Risk management.</li>
                  <li className="p-text">Develop a separate dashboard for financial metrics, enabling policy-related insights.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="case-study-card">
                <h5 className="title">Key Project Deliverables</h5>
                <div className="content key-project-content">
                <Fancybox options={{Carousel: {infinite: false,},}}>
                  <div className="row case-study-row justify-content-center">
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={digitalService8} alt="digitalService5" className="img" /></div>
                        <li className="p-text">Executive view for overall health utilization for different personas.</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={digitalService9} alt="digitalService6" className="img" /></div>
                        <li className="p-text">Patient service utilization dashboard.</li>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-card">
                        <div className="img-div"><img src={digitalService10} alt="digitalService7" className="img" /></div>
                        <li className="p-text">Patient Risk assessment measurement. </li>
                      </div>
                    </div>
                  </div>
                </Fancybox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}