import React from "react";
import { Route } from "react-router-dom";
import Home from "../pages/ibc-consultants";
import About from "../pages/about-us.js";
import Careers from "../pages/careers.js";
import Services from "../pages/services.js";
import StrategyDecisionMaking from "../pages/strategy-decision-making.js";
import OperationalExcellence from "../pages/operational-excellence.js";
import OrgTransformationChangeManagement from "../pages/org-transformation-and-change-management.js";
import DigitalTransformation from "../pages/digital-transformation.js";
import ContactUs from "../pages/contact-us.js";
import PrivacyPolicy from "../pages/privacy-policy.js";
import TermsAndConditions from "../pages/terms-and-conditions.js";
import Insights from "../pages/insights/insights.js";
import InsightsDetails from "../pages/insights/insights-details.js";

const FrontendRoute = [
    /*
    *  Static Page Routes (i.e. FAQ Page and other page where no development required)
    *
    */

    <Route key="frontend-1" path={"/"} element={<Home pageName="Home" />} />,
    <Route key="frontend-2" path={"/home.html"} element={<Home pageName="Home"/>} />,
    <Route key="frontend-3" path={"/about-us.html"} element={<About pageName="About Us" />} />,
    <Route key="frontend-4" path={"/careers.html"} element={<Careers pageName="Careers" />} />,
    <Route key="frontend-5" path={"/services.html"} element={<Services pageName="Services" />} />,
    <Route key="frontend-6" path={"/strategy-and-decision-making.html"} element={<StrategyDecisionMaking pageName="Strategy & Decision Making" />} />,
    <Route key="frontend-7" path={"/operational-excellence.html"} element={<OperationalExcellence pageName="Operational Excellence" />} />,
    <Route key="frontend-8" path={"/org-transformation-and-change-management.html"} element={<OrgTransformationChangeManagement pageName="Org. Transformation & Change Management" />} />,
    <Route key="frontend-9" path={"/digital-transformation.html"} element={<DigitalTransformation pageName="Digital Transformation" />} />,
    <Route key="frontend-10" path={"/contact-us.html"} element={<ContactUs pageName="Contact Us" />} />,
    <Route key="frontend-11" path={"/privacy-policy.html"} element={<PrivacyPolicy pageName="Privacy Policy" />} />,
    <Route key="frontend-12" path={"/terms-and-conditions.html"} element={<TermsAndConditions pageName="Terms and Conditions" />} />,
    
    /*
    * Dynamic Page Routes (i.e. Product Or Service page where development required - By Developer Only)
    *
    */	
   
   <Route key="frontend-13" path="*" element={<Home pageName="Home" />} />,
   <Route key="frontend-15" path={"/insights.html"} element={<Insights pageName="Insights" />} />,
   <Route key="frontend-16" path={"/:slug"} element={<InsightsDetails pageName="Insights Details" />} />

];

export default FrontendRoute;