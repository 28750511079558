import React from "react";
import '@fortawesome/fontawesome-free/css/all.css';
import { Link } from "react-router-dom";
import * as Constants from "../../config/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import logo from "../../assets/images/logo.png";
import linkedinIcon from "../../assets/images/linkedin.png";
import xIcon from "../../assets/images/twitter.png";
import fbIcon from "../../assets/images/facebook.png";
import youtubeIcon from "../../assets/images/youtube.png";
import instaIcon from "../../assets/images/instagram.png";

export default function Footer({ settingInfo }) {
	const { t } = useTranslation();

	return (
		<footer>			
			<section className="footer">
				<div className="container">
					<div className="row footer-row">
						<div className="col-lg-4 col-sm-12">
							<Link to={"/"} className="navbar-brand">
								{settingInfo?.website_footer_logo ? (
									<img src={Constants.BACKEND_FILE_URL + settingInfo.website_footer_logo} alt={settingInfo.site_title} className="brand-logo-img" />
								) : (
									<img src={logo} alt={settingInfo.site_title} className="brand-logo-img"/>
								)}
							</Link>
							<div className="address-mrow">
								{settingInfo?.short_address && (
									<div className="icon-text location-text">
										<i className="fi fi-rr-marker icon"></i>                
										<p className="text">{settingInfo.short_address}</p>
									</div>
								)}
								<div className="icon-text location-text">
									<i className="fi fi-rr-marker icon"></i>                
									<p className="text">Pune, India</p>
								</div>
								<div className="icon-text location-text">
									<i className="fi fi-rr-marker icon"></i>                
									<p className="text">Kirkland, USA</p>
								</div>
								<div className="icon-text location-text">
									<i className="fi fi-rr-marker icon"></i>                
									<p className="text">Melbourne, Australia</p>
								</div>
								{settingInfo?.email && (
									<a href={"mailto:" + settingInfo.email} className="icon-text">
										<i className="fi fi-rr-envelope icon"></i>                
										<p className="text">{settingInfo.email}</p>
									</a>
								)}
							</div>
						</div>
						<div className="col-lg-3 col-sm-4">
							<h3 className="footer-heading-text">Quick Links</h3>
							<ul className="list-unstyled link-list">
								<li><Link className="footer-link" to={"/"} title="Home"><i className="fi fi-rr-angle-double-small-right icon"></i> Home</Link></li>
								<li><Link className="footer-link" to={"/about-us.html"} title="About Us"><i className="fi fi-rr-angle-double-small-right icon"></i> About Us</Link></li>
								<li><Link className="footer-link" to={"/services.html"} title="Services"><i className="fi fi-rr-angle-double-small-right icon"></i> Services</Link></li>
								<li><Link className="footer-link" to={"/insights.html"} title="Insights"><i className="fi fi-rr-angle-double-small-right icon"></i> Insights</Link></li>
								<li><Link className="footer-link" to={"/careers.html"} title="Careers"><i className="fi fi-rr-angle-double-small-right icon"></i> Careers</Link></li>
								<li><Link className="footer-link" to={"/contact-us.html"} title="Contact Us"><i className="fi fi-rr-angle-double-small-right icon"></i> Contact Us</Link></li>
							</ul>
						</div>

						<div className="col-lg-5 col-sm-6">
							<h3 className="footer-heading-text">Services</h3>
							<ul className="list-unstyled link-list">
								<li><Link className="footer-link" to={"/strategy-and-decision-making.html"} title="Strategy & Decision Making"><i className="fi fi-rr-angle-double-small-right icon"></i> Strategy & Decision Making</Link></li>
								<li><Link className="footer-link" to={"/operational-excellence.html"} title="Operational Excellence"><i className="fi fi-rr-angle-double-small-right icon"></i> Operational Excellence</Link></li>
								<li><Link className="footer-link" to={"/org-transformation-and-change-management.html"} title="Org. Transformation & Change Management"><i className="fi fi-rr-angle-double-small-right icon"></i> Org. Transformation & Change Management</Link></li>
								<li><Link className="footer-link" to={"/digital-transformation.html"} title="Digital Transformation"><i className="fi fi-rr-angle-double-small-right icon"></i> Digital Transformation</Link></li>
							</ul>
							{(settingInfo?.facebook_link || settingInfo?.instagram_link || settingInfo?.twitter_link || settingInfo?.linkedin_link || settingInfo?.youtube_link) && (
								<div className="socials">
									{settingInfo?.facebook_link && (
										<li className="d-inline-block">
											<Link to={settingInfo.facebook_link} target="_blank" rel="noopener noreferrer" className="social-list-item" title="Facebook"><img src={fbIcon} alt="Facebook" className="icn-img" /></Link>
										</li>
									)}
									{settingInfo?.instagram_link &&(
										<li className="d-inline-block">
											<Link to={settingInfo.instagram_link} target="_blank" rel="noopener noreferrer" className="social-list-item" title="Instagram"><img src={instaIcon} alt="Instagram" className="icn-img" /></Link>
										</li>
									)}
									{settingInfo?.twitter_link &&(
										<Link to={settingInfo.twitter_link} target="_blank" rel="noopener noreferrer" className="social-list-item" title="X"><img src={xIcon} alt="X" className="icn-img" /></Link>
									)}
									{settingInfo?.linkedin_link &&(
										<Link  to={settingInfo.linkedin_link} target="_blank" rel="noopener noreferrer" className="social-list-item" title="Linkedin"><img src={linkedinIcon} alt="Linkedin" className="icn-img" /></Link>
									)}
									{settingInfo?.youtube_link &&(
									<li className="d-inline-block">
										<Link  to={settingInfo.youtube_link} target="_blank" rel="noopener noreferrer" className="social-list-item" title="Youtube"><img src={youtubeIcon} alt="Youtube" className="icn-img" /></Link>
									</li>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
			{(settingInfo?.site_title || settingInfo?.powered_by) && (
				<div className="copyright-footer">
					<div className="container">
						<div className="inner-content">
							{settingInfo?.site_title && (
								<p className="copyright-footer-text">&copy; {moment().year()} {settingInfo.site_title}. {t('all-rights-reserved')} </p>
							)}
							<div>
								<Link to={"/privacy-policy.html"} title="Privacy Policy" className="copyright-footer-text right-border">Privacy Policy</Link>
								<Link to={"/terms-and-conditions.html"} title="Terms and Conditions" className="copyright-footer-text">Terms and Conditions</Link>
							</div>
							{settingInfo?.powered_by && (
								<p className="copyright-footer-text">{t('developed-by')} <a href={ settingInfo?.powered_by_link ? settingInfo.powered_by_link : void(0) } target="_blank" rel="noopener noreferrer">{settingInfo.powered_by}</a></p>
							)}
						</div>
					</div>
				</div>
			)}
		</footer>
	);
}