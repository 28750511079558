import React from "react";
import Layout from "./layout.js";
import Breadcrumbs from "../component/Breadcrumbs.js";
import * as Constants from "../config/constants.js";
import { Link } from "react-router-dom";

export default function TermsAndConditions({pageName}) {

  return (
    <Layout pageName={pageName}>
      <Breadcrumbs title={pageName} text="User Agreement and Conditions of Use" />

      <section className="sec-padding border-bottom"> 
        <div className="container">
          <div className="terms-policy-content">
            <div className="p-text-div">
            <h5 className="sub-heading">Introduction</h5>
              <p className="p-text">Welcome to the IBC Consultants, accessible at <Link to={Constants.SITE_URL}>{Constants.SITE_URL}</Link>. These Terms and Conditions form a binding legal agreement between you, the user, and IBC Consultants ("Company," "we," "us," or "our"). By accessing and using our website, you signify your acceptance of these Terms. If you do not agree with any part of these Terms, you must cease using this website immediately.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Legal Capacity</h5>
              <p className="p-text">You affirm that you are over the age of 18 and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Intellectual Property Rights</h5>
              <p className="p-text">All content on this site, including text, graphics, logos, icons, images, audio clips, digital downloads, and software, is the property of the Company or its content suppliers and protected by international copyright and intellectual property laws. We grant you a limited license to access and make personal use of this site but not to download (other than page caching) or modify it, or any portion of it, except with express written consent of the Company.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">User Responsibilities</h5>
              <p className="p-text">You agree to use the website only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the website. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within our website.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Prohibited Uses</h5>
              <p className="p-text">You are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial, or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Accuracy, Completeness, and Timeliness of Information</h5>
              <p className="p-text">We are not responsible if information made available on this site is not accurate, complete, or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete, or more timely sources of information.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Modifications to the Service and Prices</h5>
              <p className="p-text">Prices for our services are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Products or Services</h5>
              <p className="p-text">Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Optional Tools</h5>
              <p className="p-text">We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Third-Party Links</h5>
              <p className="p-text">Certain content, products, and services available via our Service may include materials from third-parties. Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">User Comments, Feedback, and Other Submissions</h5>
              <p className="p-text">If, at our request, you send certain specific submissions (for example, contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate, and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Personal Information</h5>
              <p className="p-text">Your submission of personal information through the store is governed by our Privacy Policy. Please review our Privacy Policy.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Errors, Inaccuracies, and Omissions</h5>
              <p className="p-text">Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies, or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times, and availability. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Disclaimer of Warranties; Limitation of Liability</h5>
              <p className="p-text">We do not guarantee, represent, or warrant that your use of our service will be uninterrupted, timely, secure, or error-free. We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable. You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you. You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties, or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Indemnification</h5>
              <p className="p-text">You agree to indemnify, defend and hold harmless IBC Consultants Private Limited and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns, and employees, harmless from any claim or demand, including reasonable attorneys' fees, made by any third-party due to or arising out of your breach of these Terms or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Severability</h5>
              <p className="p-text">In the event that any provision of these Terms is determined to be unlawful, void, or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Termination</h5>
              <p className="p-text">The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes. These Terms are effective unless and until terminated by either you or us. You may terminate these Terms at any time by notifying us that you no longer wish to use our Services, or when you cease using our site. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Entire Agreement</h5>
              <p className="p-text">The failure of us to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. These Terms and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications, and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms).</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Governing Law</h5>
              <p className="p-text">These Terms and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Netherlands.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Changes to Terms of Service</h5>
              <p className="p-text">You can review the most current version of the Terms of Service at any time on this page. We reserve the right, at our sole discretion, to update, change, or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>
            </div>

            <div className="p-text-div">
              <h5 className="sub-heading">Contact Information</h5>
              <p className="p-text">Questions about the Terms of Service should be sent to us at <Link to={"mailto:info@consult-ibc.com"}>info@consult-ibc.com</Link>.</p>
              <p className="p-text">We hope this Terms and Conditions document helps you understand how you can and should use our website and our services. We aim to be transparent about what we expect from you and what you can expect from us. If you have any questions or suggestions about these terms, please don't hesitate to reach out to us. We are here to help and ensure your experience with IBC Consultants is both positive and clear.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}