import React from "react";

const SectionHeading = (props = {}) => {
    return (
        <>
            <div className={`common-heading ${props.headingClassName ? props.headingClassName : ''}`}>
                <h4 className="common-sub-heading">{props.secName}</h4>
                <h5 className="common-heading-text">{props.secText}</h5>
            </div>
        </>
    )
}
export default SectionHeading;