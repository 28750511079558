import React from "react";
import Layout from "./layout.js";
import Breadcrumbs from "../component/Breadcrumbs.js";
import ServicesListSection from "../component/ServicesListSection.js";
import SectionHeading from "../component/SectionHeading.js";

export default function Services({pageName}) {

  return (
    <Layout pageName={pageName}>
      <Breadcrumbs title={pageName} text="Comprehensive Service to Enhance Your Capacity and Capabilities" />

      <section className="our-approach-sec sec-padding">
        <div className="container">
          <SectionHeading secName="OUR APPROACH" secText="FAIR Framework" />

          <div className="row approach-row">
            <div className="col-lg-3 col-sm-6">
              <div className="approch-card">
                <div className="icon-div">F</div>
                <div className="text-div">
                  <h5 className="title">Frame</h5>
                  <p className="p-text">Researching, Diagnosing, Benchmarking</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="approch-card">
                <div className="icon-div">A</div>
                <div className="text-div">
                  <h5 className="title">Assess</h5>
                  <p className="p-text">Structuring, Analyzing, Evaluating</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="approch-card">
                <div className="icon-div">I</div>
                <div className="text-div">
                  <h5 className="title">Innovate</h5>
                  <p className="p-text">Solutioning, Recommending & Strategizing</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="approch-card">
                <div className="icon-div">R</div>
                <div className="text-div">
                  <h5 className="title">Realize</h5>
                  <p className="p-text">Implementing, Supporting, Monitoring</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ServicesListSection secText="Consulting Practice Areas" />
    </Layout>
  );
}