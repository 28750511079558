import React from "react";
import ButtonPrimary from "../component/ButtonPrimary.js";
import { Link } from "react-router-dom";

const InsightsCard = (props = {}) => {
    return (
        <>
            <div className={`insights-card ${props.mainClassName ? props.mainClassName : ''}`}>
                <div className="d-flex flex-column">
                    {props.title &&
                        <div className="top-content">
                            {props.link ? <Link to={props.link} className="title">{props.title}</Link> : <h6 className="title">{props.title}</h6>}
                           {props.text && <p className="p-text text">{props.text}</p>}
                        </div>
                    }
                    <div className="bottom-content">
                        <div className="date-info">
                        {props.date && <h6 className="info-text"><i className="fi fi-rr-calendar-day icon"></i> {props.date}</h6>}
                        {props.category && <h6 className="info-text"><i className="fi fi-rr-list icon"></i> {props.category}</h6>}
                        </div>
                        {props.tags &&
                            <div className="tags">
                                {props.tags.map((tags,index) => (
                                    <Link to={void(0)} onClick={e => props?.onTagClick && props?.onTagClick(e, tags)} className="tag-name" key={index}>{tags}</Link>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                {props.to && <ButtonPrimary type="link" to={props.to} title="Read More" primaryBtnClass="bg-theme-light" />}
            </div>
        </>
    )
}
export default InsightsCard;