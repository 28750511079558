import React from "react";
import bottomRightLine from "../../src/assets/images/breadcrumb-line.svg";
import TopLeftLine from "../../src/assets/images/left-top-lines.png";
import SectionHeading from "./SectionHeading";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

const TestimonialsSection = (props = {}) => {

    const defaultSliderData = [
        {id: 1, text: 'The team is very responsive, service-minded and friendly. The interaction during the scoping as well as the provision of the results was very professional. Good work researching qualitative information and finding additional sources of information that was useful for a subsequent deep-dive.', name: 'Tony Wronkowitz, Heidelberg und Umgebung'},
        {id: 2, text: 'With IBC, we are able to deliver sharper fact-based insights, more quickly, and at superior costs. We can rapidly access the brain power we need, when we need it.', name: 'Kaihan Krippendorff, Outthinker'},
        {id: 3, text: 'Flexible and responsive, IBC extend the reach and knowledge of our research department as though they are part of our staff, allowing us to focus on issues and implications that are most meaningful to our clients.', name: 'Francesco Bax, MC&A Consulting'},
        {id: 4, text: 'The team at IBC Consultants are experienced. They understood our requirements and briefs quickly, communicate regularly, and have always been prompt to respond and deliver quality reports. Exceeded expectations.', name: 'Joe Ringer, Unitive'},
        {id: 5, text: 'The IBC team has assisted us with market research and have always been very responsive and professional. We look forward to expanding our partnership with further collaboration on payments consulting projects in the future.', name: 'Mangala Martinus, Payments Consulting Network'},
        {id: 6, text: 'IBC Consultants come highly recommended as a reliable and quality-driven business partner. Their commitment to excellence is evident in every project and they consistently deliver top results. Their unique ability to mobilize skilled professionals quickly makes them a valuable asset to our team. Trust IBC Consultants to exceed your expectations, as they have consistently done for us.', name: 'Ricardo Miquelino, and dos Santos'},
        {id: 7, text: 'Good communication, fast turnaround, reliable work product.', name: 'Victor Adefuye, Dana Consulting'},
        {id: 8, text: 'IBC always deliver excellent work, they understand the project brief and deliver in a timely and efficient manner.', name: 'Abby Tomlinson, Food Trending'},
        {id: 9, text: 'Quick responses, clear understanding of scope, ability to take feedback and adjust on the fly.', name: 'Harry Pascarella, Harbor Research'},
        {id: 10, text: 'I was really impressed with the team\'s skills, professionalism, and helpfulness. They\'re a great company to work with and we highly recommend them. Great experience.', name: 'Maryna Bakuntseva, ResearchQuest'},
        {id: 11, text: 'IBC has proven to be a very efficient and reliable partner. The team has been essential in helping Pufferfish Partners delivering key market insights both to our internal team and our customers.', name: 'Arnaud Blandin, Pufferfish Partners'},
        {id: 12, text: 'Harsh and his team handled this project with ease. They happily and readily adopted feedback and folded that feedback into the deliverable. Communication was frequent, timely and thorough. Very easy to recommend IBC for this type of work.', name: 'Chris Walton, ETON Venture Services'},
        {id: 13, text: 'I highly recommend IBC. They\'re a trusted partner to analyze and make sense of data. Their service is impeccable, the turnaround time is fast, and they operate with integrity and transparency.', name: 'Kristin Harper, Driven to Succeed'},
    ];    

    const testimonialSliderData = props?.testimonialSliderData || defaultSliderData;

    return (
        <>
            <section className={`testimonials-sec ${props.sectionClass ? props.sectionClass : ''}`}>
                <img src={bottomRightLine} alt="Line" className="img-fluid bottom-right-line" />
                <img src={TopLeftLine} alt="Line" className="img-fluid top-left-line" />
                {props.children}
                <div className="container">
                    
                    <SectionHeading secName="Testimonials" secText={props.secText ? props.secText : "What our clients say…"} />

                    <Swiper 
                        pagination={{
                            dynamicBullets: true,
                            clickable: true,
                        }}
                        spaceBetween={15}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        loop={true}
                        modules={[Pagination, Autoplay]}
                    >
                        {testimonialSliderData.length !== 0 && testimonialSliderData !== undefined ? testimonialSliderData.map((data, index) => {
                            return (
                                <SwiperSlide className="slider-content" key={index}>
                                    <div className="inner-content">
                                        <p className="p-text">{data.text}</p>
                                        <h6 className="name">{data.name}</h6>
                                    </div>
                                </SwiperSlide>
                            )
                        }) : ''}
                    </Swiper>
                </div>
            </section>
        </>
    )
}
export default TestimonialsSection;